import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';
import { EventContainer } from '../components/EventContainer';
import { LineBreak } from '../components/LineBreak';
import { IconLink } from '../components/IconLink';
import { Button } from '../components/Button';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">Gallery</div>
      <main className="foundation_spacing_section">
        <div className="feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>John Akomfrah</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Mnemosyne, 2010
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    A haunting exploration of memory, myth and migration
                  </p>
                  <p>
                    Mnemosyne was the mother of the Nine Muses, the
                    personification of memory in Greek mythology.
                  </p>
                  <p>
                    Akomfrah's vital and moving tone poem is split into verses
                    named after the daughters of Mnemosyne: Tragedy, History,
                    Music, Sacred Song, Astronomy, Comedy, Erotic Love and
                    Dance. Akomfrah uses a vast array of archival material to
                    hauntingly recast and retell the experiences of postwar
                    immigrants - resuscitating the now familiar images of
                    multicultural and diasporic histories.
                  </p>
                  <p>
                    Centering on the West Midlands from 1960 - 1981 and
                    interlaced with images of an unidentified frozen wasteland,
                    contemporary 'portraits' of Birmingham, narrative and
                    literary voices and a dub-infused soundtrack, Mnemosyne is
                    an evocative bricolage which questions memory and suggests
                    the possibility for endless re-interpretation of historical
                    events.
                  </p>
                  <p>
                    Devised and scripted from the writings of an array of
                    authors including Dante Alighieri, Samuel Beckett, Emily
                    Dickinson, James Joyce, John Milton, Friedrich Nietzsche,
                    William Shakespeare, Sophocles and Dylan Thomas, Mnemosyne
                    is a set of imaginary journeys through myth, folklore,
                    history and a museum of intangible things.
                  </p>
                  <br />
                  <p>IMAGE</p>
                  <p>Mnemosyne, 2010</p>
                  <p>Single channel HD colour video, 5.1 sound</p>
                  <p>45 minutes 6 seconds</p>
                  <p>ed. of 5 + 2 APs</p>
                  <p>
                    © John Akomfrah / Smoking Dog Films / courtesy of Lisson
                    Gallery and the artist
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 23 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Exhibition Gallery One, Grosvenor Museum, 27 Grosvenor
                        Street, Chester, CH1 2DD
                      </strong>
                    </p>
                    <p>FREE - no need to book</p>
                    <p>
                      <strong>Open</strong> Tues to Sat 10.30am-5pm (last
                      admission 4.15pm) Sunday 1-4pm (last admission 3.15pm).
                      Closed Mondays.
                    </p>
                    <p>
                      <strong>Access</strong> Exhibition Gallery One is on the
                      ground floor of the Museum and is accessible via lift from
                      street level. Other floors of the Museum have no lift
                      access.
                    </p>
                  </Typography>
                  <Button href="#">Button</Button>
                </EventContainer>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-11"
                data-grid-start="m-2"
              >
                <LineBreak />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <div className="iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/7gcWQT3lU7I"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section_grey spacing_top-medium spacing_bottom-medium">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-third m-third l-third"
              >
                <ContentCard
                  title="Title"
                  blurb="lorem ipsum"
                  category="Category"
                  image="/content-images/Image-0.png"
                  href="#"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-third m-third l-third"
              >
                <ContentCard
                  title="Title"
                  blurb="lorem ipsum"
                  category="Category"
                  image="/content-images/Image-1.png"
                  href="#"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-third m-third l-third"
              >
                <ContentCard
                  title="Title"
                  blurb="lorem ipsum"
                  category="Category"
                  image="/content-images/Image-2.png"
                  href="#"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
