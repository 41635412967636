import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
import { Hero } from '../components/Hero';

console.log(responsiveGrid, typography);

export function Artworks(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="section_grey">
        <Hero
          imgSrc="/content-images/38c54f387ee2722b2a15c527d852cbfb.jpg"
          altText="alt text"
        />
      </div>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>Artworks</h1>
                <p>
                  You'll find Chester Contemporary artworks and happenings
                  across the city in its different spaces and places. All are
                  free to enjoy.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="John Akomfrah "
                  blurb="Mnemosyne, 2010"
                  image="/content-images/johnak/AKOM100001_12.jpg"
                  href="/JohnAkomfrah"
                  location="Grosvenor Museum"
                  date="24 Sept – 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Fiona Banner aka The Vanity Press"
                  blurb="DISARM, 2023"
                  image="/content-images/DIS-ARM.jpg"
                  href="/FionaBanner"
                  location="Castlefield Gallery New Art Spaces: Chester, Grosvenor Shopping Centre"
                  date="23 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Simeon Barclay"
                  blurb="Them Over Road, 2023"
                  image="/content-images/38c54f387ee2722b2a15c527d852cbfb.jpg"
                  href="/SimeonBarclay"
                  location="Various sites"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Jacq Bebb "
                  blurb="Skulking the In-Betweens (Queer Time) / Skulking the In-Betweens (Desirous Bind), 2023"
                  image="/content-images/9c3f5c1a0980e848e7b5481670ead452.jpg"
                  href="/JacqBebb"
                  location="Watergate Row / The Roman South East Corner Tower Garden"
                  date="22 Sept - 1 Dec"
                />
              </div>
              {/* row */}
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Charlotte Van Berckel"
                  blurb="Chester from Boughton, Reimagined / My Finest Swing / Making Merry / The Visitors’ Book, 2023"
                  image="/content-images/a341e7d6339dc781d955a255be969023.jpg"
                  href="/CharlottevanBerckel"
                  location="Various sites"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Nick Davies"
                  blurb="A Sweet Connection (Sorry Paul), Way After Goteddsday / It Only Really Dies When It’s Forgotten, 2023"
                  image="/content-images/dd1a7e9886749b6ac3f617dd76dbae5d.jpg"
                  href="/NickDavies"
                  location="Various sites"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Peter Fischli David Weiss"
                  blurb="How to Work Better, 1991"
                  image="/content-images/f3638f91a7b64475fdcc76436af16ed3.jpg"
                  href="/FischliWeiss"
                  location="Northgate Forum wall"
                  date="22 Sept - 1 Dec"
                />
              </div>

              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Tim Foxon "
                  blurb="Contemporary Interventions, 2023"
                  image="/content-images/585e0c3bc8c47a9933c98ae5e2c94a91.jpg"
                  href="/TimFoxon"
                  location="Various sites"
                  date="23 Sept - 1 Dec"
                />
              </div>
              {/* row */}
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Ryan Gander"
                  blurb="Places I go when I can’t sleep (Explosive plans centring from the periphery) / The Cestrian Ya-Ya, 2023 / Centred on the periphery, 2023"
                  image="/content-images/b73057c247719993453a1b9e2dfb9bac.jpg"
                  href="/RyanGander"
                  location="Various sites"
                  date="23 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Harry Grundy"
                  blurb="Janus Wept / The Mingling Tree, 2023"
                  image="/content-images/0f63df88c5ef32f764a61970b9e2e22e.png"
                  href="/HarryGrundy"
                  location="Various sites"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="William Lang"
                  blurb="Crop, 2023"
                  image="/content-images/68355207d00cd96cf08c3ee473a4f651.jpg"
                  href="/WilliamLang"
                  location="The Dean’s Field, Chester Cathedral / Various sites"
                  date="23 Sept - 1 Dec"
                />
              </div>

              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="James Lomax"
                  blurb="Markets shift like sand V, 2023"
                  image="/content-images/081fcbcbc0e16d398a1353cbdc06553a.jpg"
                  href="/JamesLomax"
                  location="Various sites"
                  date="22 Sept - 1 Dec"
                />
              </div>
              {/* row */}
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Patricia Mackinnon-Day "
                  blurb="The Start of All Imaginings, 2023"
                  image="/content-images/1de9eb1d804a24e5764bb41ab25d3ea4.jpg"
                  href="/PatriciaMackinnon-Day"
                  location="Various sites"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Hannah Perry"
                  blurb="No Tracksuits, No Trainers, 2023"
                  image="/content-images/2363129ba029693a23a0efe747ecfa13.jpg"
                  href="/HannahPerry"
                  location="Forum Market Depot"
                  date="23 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Elizabeth Price"
                  blurb="HORSETAILS, 2023"
                  image="/content-images/1b81f9c0f1b2adeee582ec71d0aec5a6.jpg"
                  href="/ElizabethPrice"
                  location="Chester Cathedral"
                  date="24 Sept - 26 Nov"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artworks;
