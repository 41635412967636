import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';
import { EventContainer } from '../components/EventContainer';
import { LineBreak } from '../components/LineBreak';
import { IconLink } from '../components/IconLink';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

const containerStyle = {
  width: '100%',
  height: '100%',
  aspectRatio: '4 / 3',
};

const center = {
  lat: 53.190454,
  lng: -2.8914872,
};

// const loader = new Loader({
//   apiKey: 'AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg',
//   version: 'weekly',
// });

function MyComponent(props) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCy4uGjVXKeMFldcUuBzlGyL1zSUtJy93k',
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>Chester Contemporary map</h1>
                <p className="typography_heading-2">
                  Find your way around the walking biennial
                </p>
                <p>
                  You’ll find all Chester Contemporary artworks and events
                  within the city centre which is compact and easy to navigate.
                  All venues, places and spaces are listed here in one handy
                  map. Click on the links below to find out more about each
                  artwork or event.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="spacing_top-medium spacing_bottom-medium">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div className="element_grid-item " data-grid-size="s-12">
              <div className="iframe">
                <iframe src="https://snazzymaps.com/embed/507704"></iframe>
              </div>
            </div>
          </div>
          <div className="element_grid-row">
            <div
              className="element_grid-item "
              data-grid-size="s-12 m-half l-03"
            >
              <h3>Artworks</h3>
              <IconLink href="/JohnAkomfrah" align="top" content="1">
                John Akomfrah, Mnemosyne
              </IconLink>
              <IconLink href="/FionaBanner" align="top" content="2">
                Fiona Banner aka The Vanity Press, DIS<em>ARM</em>
              </IconLink>
              <IconLink href="/SimeonBarclay" align="top" content="3">
                Simeon Barclay, Them Over Road
              </IconLink>
              <IconLink href="/JacqBebb" align="top" content="4">
                Jacq Bebb, Skulking the In-Betweens, (Queer Time) / (Desirous
                Binds)
              </IconLink>
              <IconLink href="/CharlottevanBerckel" align="top" content="5">
                Charlotte van Berckel, Chester from, Boughton, 1879 by John
                Finnie, reimagined / My Finest Swing
              </IconLink>
              <IconLink href="/NickDavies" align="top" content="6">
                Nick Davies, A Sweet Connection, Sorry Paul (Way After
                Goteddsday) / It Only Really Dies When It's Forgotten
              </IconLink>
              <IconLink href="/FischliWeiss" align="top" content="7">
                Peter Fischli David Weiss, How to Work Better
              </IconLink>
            </div>
            <div
              className="element_grid-item "
              data-grid-size="s-12 m-half l-03"
            >
              <IconLink href="/RyanGander" align="top" content="8">
                Ryan Gander, Places I go when I can't sleep (Explosive plans
                centring from the periphery) / The Cestrian Ya-Ya / Centred on the periphery
              </IconLink>
              <IconLink href="/HarryGrundy" align="top" content="9">
                Harry Grundy, Janus Wept / The Mingling Tree
              </IconLink>
              <IconLink href="/WilliamLang" align="top" content="10">
                William Lang, Crop
              </IconLink>
              <IconLink href="/JamesLomax" align="top" content="11">
                James Lomax, Markets shift like sand V, 2023
              </IconLink>
              <IconLink href="/PatriciaMackinnon-Day" align="top" content="12">
                Patricia Mackinnon-Day, The Start of All Imaginings
              </IconLink>
              <IconLink href="/HannahPerry" align="top" content="14">
                Hannah Perry, No Tracksuits, No Trainers
              </IconLink>
              <IconLink href="/ElizabethPrice" align="top" content="13">
                Elizabeth Price, HORSETAILS
              </IconLink>

            </div>
            <div
              className="element_grid-item "
              data-grid-size="s-12 m-half l-03"
            >
              <h3>Schools' programme </h3>{' '}
              <IconLink href="/CherryGrovePrimarySchool" align="top" content="1" colour="blue">
                Rachel Green & Cherry Grove Primary School
              </IconLink>
              <IconLink href="/BelgravePrimarySchool" align="top" content="2" colour="blue">
                Lucy Dentith & Belgrave Primary School
              </IconLink>
              <IconLink href="/CapenhurstCEPrimarySchool" align="top" content="3" colour="blue">
                Olivia Glasser & Capenhurst CE Primary School
              </IconLink>
              <IconLink href="/GuildenSuttonCofEPrimarySchool" align="top" content="4" colour="blue">
                Sarah Gallagher-Hayes & Guilden Sutton C of E Primary School
              </IconLink>
              <IconLink href="/JHGodwinPrimarySchool" align="top" content="5" colour="blue">
                Lucy Dentith & JH Godwin Primary School
              </IconLink>
              <IconLink href="/MickleTraffordVillageSchool" align="top" content="6" colour="blue">
                Claire Weetman & Mickle Trafford Village School
              </IconLink>
              <IconLink href="/StOswaldsCEPrimarySchool" align="top" content="7" colour="blue">
                Dave Bixter & St Oswald's CE Primary School
              </IconLink>
            </div>
            <div
              className="element_grid-item "
              data-grid-size="s-12 m-half l-03"
            >
              <IconLink href="/StColumbasCatholicPrimarySchool" align="top" content="8" colour="blue">
                Clare Owens & St Werburgh's & St Columba's Catholic Primary
                School
              </IconLink>
              <IconLink href="/GrosvenorParkAcademyDeePointPrimarySchool" align="top" content="9" colour="blue">
              Neuza Morais & Grosvenor Park Academy / Dee Point Primary School
              </IconLink>
              <h3 style={{ marginTop: 'var(--space-base)' }}>
                Walks + Talks + Workshops
              </h3>
              <IconLink href="/HopeIsAGroupProject" align="top" content="1" colour="green">
                Hope is a group project - creative workshop with Donna Leishman
              </IconLink>
              <IconLink href="/ImpossibleStairs" align="top" content="2" colour="green">
                Impossible Stairs - creative workshop with Donna Leishman
              </IconLink>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
