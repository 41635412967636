import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/585e0c3bc8c47a9933c98ae5e2c94a91.jpg"
          altText="alt text"
          credit="Image: © Tim Foxon. Photography: Ryan Gander Studio."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Tim Foxon</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Contemporary interventions, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    A series of playful happenings across Chester. Catch them if
                    you can.
                  </p>
                  <p>
                    Playful pop-up animations, ersatz tourist campaigns,
                    self-portraits drawn by you, a bicycle in unexpected places,
                    an insect murder mystery….all the work of Tim Foxon, one of
                    Chester’s most eclectic artists.
                  </p>
                  <p>
                    Use the hashtag #CCTim on Instagram to find out what’s
                    happening when, and where.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 23 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>Various locations</strong> Keep up to date with
                      the latest Tim Foxon happenings using #CCTim
                    </p>
                  </Typography>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Tim Foxon (b. 1985, Chester, UK)</strong> is an
                    artist who lives and works in Chester. His drawings are
                    often one-liners and always of a playful nature. The scale
                    and reinterpretation of everyday objects and situations
                    transform into vehicles for the ridiculous. His sculptural
                    work is also full of humour, yet with an element of sadness,
                    which he describes as "like going to a friend’s birthday
                    party and filling the piñata with their mum’s best china.”
                    Tim studied sculpture at Wimbledon College of Art and has
                    exhibited his work worldwide.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Tim Foxon’s work is part of Chester Contemporary’s Core
                      Artist programme curated in conjunction with the Emerging
                      Artist programme by artistic director, Ryan Gander.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
