import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        {/* <Hero
          imgSrc="/content-images/walks/9f2f91dd71c22cdced4f189624ea3ad1.jpg"
          altText="alt text"
          credit="Image ©  Donna Leishman"
        /> */}
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Gateway – creative walk and workshop</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  With Wendy Connelly and Robin Bailey
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Join RAW-i Studio’s artist Wendy Connelly and poet Robin Bailey (UrbanHerne) for a creative
walk shaped by the landscape of Chester – its waterways and captured history. Gathering
inspirational ideas along the way, the walk will culminate in an hour-long hands-on creative
workshop, using found fragments to help discover and capture a sense of place. The
drawings, conversations and words from the walk and workshop will be brought together at
an exhibition in the Grosvenor Museum from November 4 - December 31.
                  </p>
                  <p>
                    <em>The walk will begin at Cathedral Gardens – meeting at the corner of St Werburgh Street
adjacent to the Bell Tower Walk and will end at Chester Visual Arts Gallery (CVA) in the
Grosvenor Shopping Centre (former H&amp;M unit) where there will be a one-hour collaborative
workshop and light refreshment.</em>
                  </p>
                  <p>
                    <em>The walk will take from between one and half to two hours at a moderate pace, and include
points of rest along the way. Suitable footwear is required as the paths can be damp and
muddy. Please dress for the weather as we will be outside for much of this session</em>
                  </p>
                  <p><strong>The Gateway Project</strong> is a project born of a desire to connect and reimagine familiar spaces
in what we call our home, our city of Chester. Artist Wendy Connelly and poet Robin Bailey
(UrbanHerne) created Gateway for the Chester Contemporary. The landscape of the city –
its waterways and captured history – have become familiar. Avenues for walks provides
opportunities to capture a shared response through conversations, poetry, and drawings.
Individuals and representatives of our city will capture moments through walks and reflective
workshops. Working with and alongside Wendy and Robin these familiar and curious
fragments will help us discover and capture a sense of place. A public exhibition will bring
together the drawings, conversations, and words.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 27 Oct, 10am-1pm</strong>
                    </p>
                    <p>
                      2pm meet at the corner of St Werburgh Street adjacent to the Bell Tower Walk
4pm workshop at Chester Visual Arts (CVA), Grosvenor Shopping Centre
5pm session ends
                    </p>

                    <p>
                      Suitable for ages 16+
                    </p>
                    <p>
                    <strong>access</strong>{' '}
The majority of the walk is along well-formed pathways. There are two areas
where there are steps down to the waterways and/or slopes which may be difficult for
wheelchair users or those with mobility issues.
                    </p>
                    <p>
                    <strong>Chester Visual Arts Gallery workshop access</strong>{' '}
Unit has level access from shopping centre
                    </p>
                    <p>
                      <strong>£5</strong> includes light refreshment
Booking required

                    </p>
                    <Button className='spacing_bottom-medium'
                    href={'https://www.ticketsource.co.uk/chestercontemporary/gateway-creative-walk-and-workshop-with-wendy-connelly-and-urbanherne/e-yxjplr'}
                  >
                    Book Here
                  </Button>
                  </Typography>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  {/* <p>
                    <strong>Donna Leishman‘s</strong> work is a combination of
                    critical writing, and digital and visual art with a
                    particular focus on the intersection of stories,
                    interactivity, and identity. Her work explores the reasons
                    why stories were created, and explores what new
                    possibilities they offer us culturally, by reframing
                    history, using folkloric traditions, and problematising
                    archetypes. Donna has had her work exhibited in museums,
                    galleries, conferences and festivals around the world.
                  </p> */}
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
