import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/index.css';
import App from './App';
import { Home } from './pages/Home';
import { Events } from './pages/Events';
import { Artist } from './pages/Artist';
import { Visit } from './pages/Visit';
import { About } from './pages/About';
import { GetInvolved } from './pages/GetInvolved';
import { PastProjects } from './pages/PastProjects';
import Maps from './pages/Map';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './pages/Error';
import Zine from './pages/Zine';
import BelgravePrimarySchool from './pages/Schools/BelgravePrimarySchool';
import CapenhurstCEPrimarySchool from './pages/Schools/CapenhurstCEPrimarySchool';
import CherryGrovePrimarySchool from './pages/Schools/CherryGrovePrimarySchool';
import GrosvenorParkAcademyDeePointPrimarySchool from './pages/Schools/GrosvenorParkAcademyDeePointPrimarySchool';
import GuildenSuttonCofEPrimarySchool from './pages/Schools/GuildenSuttonCofEPrimarySchool';
import JHGodwinPrimarySchool from './pages/Schools/JHGodwinPrimarySchool';
import MickleTraffordVillageSchool from './pages/Schools/MickleTraffordVillageSchool';
import StColumbasCatholicPrimarySchool from './pages/Schools/StColumbasCatholicPrimarySchool';
import StOswaldsCEPrimarySchool from './pages/Schools/StOswaldsCEPrimarySchool';
import Artworks from './pages/Artworks';
import Schools from './pages/Schools';
import Walkstalksworkshops from './pages/walkstalksworkshops';

import CB from './pages/Artists/CharlottevanBerckel';
import EP from './pages/Artists/ElizabethPrice';
import FB from './pages/Artists/FionaBanner';
import FW from './pages/Artists/FischliWeiss';
import HP from './pages/Artists/HannahPerry';
import HG from './pages/Artists/HarryGrundy';
import JB from './pages/Artists/JacqBebb';
import JL from './pages/Artists/JamesLomax';
import JA from './pages/Artists/JohnAkomfrah';
import ND from './pages/Artists/NickDavies';
import PMD from './pages/Artists/PatriciaMackinnon-Day';
import RG from './pages/Artists/RyanGander';
import SB from './pages/Artists/SimeonBarclay';
import TF from './pages/Artists/TimFoxon';
import WL from './pages/Artists/WilliamLang';

import HopeIsAGroupProject from './pages/WalksTalkWorkshops/HopeIsAGroupProject';
import ChesterContemporaryintheCity from './pages/WalksTalkWorkshops/ChesterContemporaryintheCity';
import ImpossibleStairs from './pages/WalksTalkWorkshops/ImpossibleStairs';
import TheArchaeologyofChesterContemporary from './pages/WalksTalkWorkshops/TheArchaeologyofChesterContemporary';
import BeneaththeSurface from './pages/WalksTalkWorkshops/BeneaththeSurface';
import HiddenGemsofChester from './pages/WalksTalkWorkshops/HiddenGemsofChester';

import Gateway from './pages/WalksTalkWorkshops/Gateway';
import Print from './pages/WalksTalkWorkshops/Print';
import Make from './pages/WalksTalkWorkshops/Make';
import Chris from './pages/WalksTalkWorkshops/chrisclayton';
import Roam from './pages/WalksTalkWorkshops/Roam';
import HeritageHarvest from './pages/WalksTalkWorkshops/HeritageHarvest';

import Dynamic from './pages/dynamic';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home title="Chester Contemporary - " />,
      },
      {
        path: 'index.html',
        element: <Home title="Chester Contemporary - " />,
      },
      {
        path: 'events/',
        element: <Events title="Chester Contemporary - " />,
      },
      {
        path: 'artist/',
        element: <Artist title="Chester Contemporary - " />,
      },
      {
        path: 'map/',
        element: <Maps title="Chester Contemporary - " />,
      },
      {
        path: 'visit/',
        element: <Visit title="Chester Contemporary - " />,
      },
      {
        path: 'about/',
        element: <About title="Chester Contemporary - " />,
      },
      {
        path: 'getinvolved/',
        element: <GetInvolved title="Chester Contemporary - " />,
      },
      {
        path: 'zine/',
        element: <Zine title="Chester Contemporary - " />,
      },
      {
        path: 'PastProjects/',
        element: <PastProjects title="Chester Contemporary - " />,
      },
      {
        path: 'BelgravePrimarySchool/',
        element: (
          <BelgravePrimarySchool title="Lucy Dentith & Belgrave Primary School" />
        ),
      },
      {
        path: 'CapenhurstCEPrimarySchool/',
        element: (
          <CapenhurstCEPrimarySchool title="Olivia Glasser & Capenhurst CE Primary School" />
        ),
      },
      {
        path: 'CherryGrovePrimarySchool/',
        element: (
          <CherryGrovePrimarySchool title="Rachel Green & Cherry Grove Primary School" />
        ),
      },
      {
        path: 'GrosvenorParkAcademyDeePointPrimarySchool/',
        element: (
          <GrosvenorParkAcademyDeePointPrimarySchool title="Neuza Morais & Grosvenor Park Academy /Dee Point Primary School" />
        ),
      },
      {
        path: 'GuildenSuttonCofEPrimarySchool/',
        element: (
          <GuildenSuttonCofEPrimarySchool title="Sarah Gallagher-Hayes & Guilden Sutton C of E Primary School" />
        ),
      },
      {
        path: 'JHGodwinPrimarySchool/',
        element: (
          <JHGodwinPrimarySchool title="Lucy Dentith & JH Godwin Primary School" />
        ),
      },
      {
        path: 'MickleTraffordVillageSchool/',
        element: (
          <MickleTraffordVillageSchool title="Claire Weetman & Mickle Trafford Village School" />
        ),
      },
      {
        path: 'StColumbasCatholicPrimarySchool/',
        element: (
          <StColumbasCatholicPrimarySchool title="Clare Owens & St Werburgh's & St Columba's Catholic Primary School" />
        ),
      },
      {
        path: 'StOswaldsCEPrimarySchool/',
        element: (
          <StOswaldsCEPrimarySchool title="Dave Bixter & St Oswald's CE Primary School" />
        ),
      },
      {
        path: 'Artworks/',
        element: <Artworks title="Chester Contemporary - Artworks" />,
      },
      {
        path: 'Schools/',
        element: <Schools title="Chester Contemporary - Schools" />,
      },
      {
        path: 'Walkstalksworkshops/',
        element: (
          <Walkstalksworkshops title="Chester Contemporary - Walks + talks + workshops" />
        ),
      },
      {
        path: 'CharlottevanBerckel/',
        element: <CB title="Chester Contemporary - Charlotte van Berckel" />,
      },
      {
        path: 'ElizabethPrice/',
        element: <EP title="Chester Contemporary - " />,
      },
      {
        path: 'FionaBanner/',
        element: <FB title="Chester Contemporary - " />,
      },
      {
        path: 'FischliWeiss/',
        element: <FW title="Chester Contemporary - " />,
      },
      {
        path: 'HannahPerry/',
        element: <HP title="Chester Contemporary - " />,
      },
      {
        path: 'HarryGrundy/',
        element: <HG title="Chester Contemporary - " />,
      },
      {
        path: 'JacqBebb/',
        element: <JB title="Chester Contemporary - " />,
      },
      {
        path: 'JamesLomax/',
        element: <JL title="Chester Contemporary - " />,
      },
      {
        path: 'JohnAkomfrah/',
        element: <JA title="Chester Contemporary - " />,
      },
      {
        path: 'NickDavies/',
        element: <ND title="Chester Contemporary - " />,
      },
      {
        path: 'PatriciaMackinnon-Day/',
        element: <PMD title="Chester Contemporary - " />,
      },
      {
        path: 'RyanGander/',
        element: <RG title="Chester Contemporary - " />,
      },
      {
        path: 'SimeonBarclay/',
        element: <SB title="Chester Contemporary - " />,
      },
      {
        path: 'TimFoxon/',
        element: <TF title="Chester Contemporary - " />,
      },
      {
        path: 'WilliamLang/',
        element: <WL title="Chester Contemporary - " />,
      },
      {
        path: 'HopeIsAGroupProject/',
        element: <HopeIsAGroupProject title="Chester Contemporary - " />,
      },
      {
        path: 'ImpossibleStairs/',
        element: <ImpossibleStairs title="Chester Contemporary - " />,
      },
      {
        path: 'ChesterContemporaryintheCity/',
        element: (
          <ChesterContemporaryintheCity title="Chester Contemporary - " />
        ),
      },
      {
        path: 'TheArchaeologyofChesterContemporary/',
        element: (
          <TheArchaeologyofChesterContemporary title="Chester Contemporary - " />
        ),
      },
      {
        path: 'BeneaththeSurface/',
        element: <BeneaththeSurface title="Chester Contemporary - " />,
      },
      {
        path: 'HiddenGemsofChester/',
        element: <HiddenGemsofChester title="Chester Contemporary - " />,
      },
      {
        path: 'chrisclayton/',
        element: <Chris title="Chester Contemporary - " />,
      },
      {
        path: 'Gateway/',
        element: <Gateway title="Chester Contemporary - " />,
      },
      {
        path: 'Print/',
        element: <Print title="Chester Contemporary - " />,
      },
      {
        path: 'Make/',
        element: <Make title="Chester Contemporary - " />,
      },
      {
        path: 'Roam/',
        element: <Roam title="Chester Contemporary - " />,
      },
      {
        path: 'HeritageHarvest/',
        element: (
          <HeritageHarvest title="Chester Contemporary - Heritage Harvest" />
        ),
      },
      {
        path: 'artist/*',
        element: <Dynamic title="Chester Contemporary - Heritage Harvest" />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
