import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';

import pach1 from '../images/PACH12.jpg'
import pach2 from '../images/PACH8.jpg'
import pach3 from '../images/PACH2.jpg'
import pach4 from '../images/PACH14.jpg'

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Zine(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <main className="foundation_spacing_section">
        <div className="feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-start="m-2"
                data-grid-size="s-12 m-10 l-8"
              >
                <Typography>
                  <h1>CC 'zine</h1>
                  <p className="typography_heading-2">
                    Browse Chester Contemporary's artist interviews, blogs and
                    the latest news.
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="28 February, 2023"
                  blurb="The launch of Chester Contemporary in February with Councillor Louise Gittins, and Artist Director Ryan Gander in conversation with featured artist Simeon Barclay."
                  video={`<iframe src="https://www.youtube.com/embed/eaWoK8QNf_g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="28 February, 2023"
                  blurb="Chester Contemporary artist Tim Foxon explains his No Look Portraits – watch him in action!"
                  video={`<iframe src="https://www.youtube.com/embed/7TgapMt5U14" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="28 February, 2023"
                  blurb="Artist Patricia Mackinnon-Day introduces her work The Start of all Imaginings at the launch of Chester Contemporary."
                  video={`<iframe src="https://www.youtube.com/embed/9tmNDI4Bbi4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
            </div>
            

          </div>
        </div>
        <div className="feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                  Place for the Arts in Chester
                  </p>
                  <p>
                  
Back in 2018, a meeting between Cheshire West &amp; Chester Council and a Chester art gallery led to a
simple yet innovative idea being hatched: that the arts had the potential to play a significant role in
the economic regeneration of Chester city centre by enabling working artists to make temporary use
of vacant retail premises, whilst simultaneously growing the market place for the arts and crafts.                  </p>
                  <p>
A community interest company (CIC), now known as Place for the Arts in Chester – or simply PACh –
was formed in 2019 as other local business people got involved, with the CIC being granted
discretionary business rate relief from the council who were already in the process of forming a
Visual Arts Forum.                  </p>
                  <p>
Initially, two spaces opened in 2019, each accommodating six artists. These then closed because of
the COVID-19 pandemic and as landlords decided to redevelop their properties. PACh then started
trading in 2020 in between lockdowns, and in 2021 two more pop-up shops opened, this time
displaying the work of 20 artists.                  </p>
                  <p>
PACh now has three years of successful operations and significant growth under its belt, breaking
even without any subsidy necessary. Five empty shop units have been used and the work of more
than 50 local artists has been displayed and sold: the model has successfully achieved ‘proof of
concept’.                  </p>
                  <p>
‘Place 5’ at 22 Bridge Street opened soon after ‘Place 4’ closed due to the landlord securing a
commercial tenant. Place 5 was not only a large gallery over two floors, but also came with a breath-
taking spacious top floor gallery where one month exhibitions from other local artists or arts groups
took place on a regular basis. Place 5 is closing early September, and PACh remain on the look out
for more premises.                  </p>
                  <p>
PACh currently has two operating galleries with 30 local artists showcasing their work, ten of whom
have been with us from the very beginning. In one of our galleries, we have also hosted a local arts
group with 30 more artists involved, so over 60 local artists have been displayed and sold with Place
for the Arts in Chester in 2023 alone!
                  </p>
                  <br />
                  <p className="typography_heading-2">The idea is straightforward, but complicated to make work...</p>
                  <p>
PACh takes an empty shop, concludes a short term deal with the landlord, and opens a pop-up art
gallery.</p>
                  <p>
We then find local artists who transform the premises by displaying their artworks; the artists also
work in the gallery.</p>
                  <p>
No paid staff are required as the galleries are manned, cleaned and maintained by the artists at a
low cost and in the best interest of the community.</p>
                  <p>
Each artist benefits from showing their work in the heart of Chester, but only have to be in the shop
for one day a week, allowing them plenty of time for creativity, and at the same time becoming a
part of an inspiring and supportive community of artists.</p>
                  <p>
Customers can enjoy a wide variety of artworks and also get to meet the artists on site, whilst other
artists pop in for inspiration and support. Both locals and visitors to Chester also give us positive
feedback, commenting on what a great idea it is, often leaving with a newly acquired piece of
original art.</p>
                  <p>
Chester in turn benefits from having open shops – not boarded-up windows – that locals and visitors
can explore, while cafés and restaurants nearby often see an increase in customers. Landlords have
premises that are being used, heated and insured by PACh rather than standing idle, all of which
enhances the ambience of the city.</p>
<p>Interestingly, PACh has also discovered that pop-ups can play a role in the way city centres transition
from retail to more of a residential and hospitality mix, occupying spaces while the necessary
planning and design processes are taking place.</p>
                  <p>
It’s great that with the support of landlords’ and the council the PACh model is completely self-
funded, with any profits invested back into facilities and artists.</p>
                  <p>
Without the help of Cheshire West &amp; Chester Council, local landlords, such as Chester Race Company
Ltd and Grosvenor Properties, and all the hard work of the local artists and enthusiasts, PACh would
have remained just a good idea. The continuation of the project depends on a pipeline of premises
being available into the future, but Chester makes things happen!</p>
                  <p>
PACh is delighted to be part of Chester Contemporary during Autumn 2023, as well as being a
central part of city’s visual arts strategy, and we hope to further develop the concept into the future,
ideally also identifying specific studio space in Chester.</p>
                  <p>
Please get in contact at <a href="mailto:artcentrechester@gmail.com">artcentrechester@gmail.com</a> if you know of retail space or potential studio
space in Chester city centre that PACh might be able to use, so that we can continue to make this
project a successful one, and give more artists and visitors a chance to enjoy its fantastic benefits.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <img src={pach1} style={{width:'100%', margin:'0 0 8px' }} />
                <img src={pach2} style={{width:'100%', margin:'0 0 8px' }} />
                <img src={pach3} style={{width:'100%', margin:'0 0 8px' }} />
                <img src={pach4} style={{width:'100%', margin:'0 0 8px' }} />
              </div>
              </div>
            </div>
          </div>
      </main>
    </>
  );
}

export default Zine;
