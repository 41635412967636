import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Hero } from '../components/Hero';
import { Typography } from '../components/Typography';
import { IconLink } from '../components/IconLink';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function GetInvolved(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="section_grey">
        <Hero
          imgSrc="/content-images/Easels.png"
          altText="alt text"
          credit="Image © Catharine Braithwaite"
        />
      </div>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>Volunteer with Chester Contemporary</h1>
                <p className="typography_heading-2">
                  A chance to volunteer, network, and join in with the Chester
                  Contemporary Fringe. Read on.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="feature_white">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-size="s-12 m-10"
              data-grid-start="m-2"
            >
              <Typography>
                <h2>
                  A chance to volunteer, network, and join in with the Chester
                  Contemporary Fringe.{' '}
                </h2>
                <p>
                  Chester Contemporary wouldn't exist without a team of
                  fantastic volunteers that help to power everything from
                  welcoming visitors to installing artworks. It's a great chance
                  to meet new people and try new things, fitting them around
                  your daily work or other commitments. Have fun helping to
                  create and support Chester Contemporary.
                </p>
                <p>
                  You can find out more{' '}
                  <a href="/CC-Volunteer-01.pdf" target="_blank">
                    here
                  </a>
                  , or email{' '}
                  <a
                    href="mailto:volunteer@chestercontemporary.org"
                    target="_blank"
                  >
                    volunteer@chestercontemporary.or
                  </a>{' '}
                  to chat to us.
                </p>
                <h2>Artist development & networking</h2>
                <p>
                  Chester has a vibrant, thriving visual arts scene with
                  individual artists, collectives, groups and galleries all
                  contributing to a creative city. Chester Contemporary aims to
                  help promote and enable this scene to flourish by offering
                  artist development opportunities for new and established
                  artists and organisations. The Lonely Arts Club is a regular
                  informal networking event open to the public and Chester (and
                  Cheshire) creatives that includes guest artist talks,
                  professional development advice, and more. To keep up to date
                  with the latest news, sign up for the{' '}
                  <a
                    href="https://cheshirewestandchester.us8.list-manage.com/subscribe?u=068c0d440921fc0d0ff8851f3&id=50f52232ca"
                    target="_blank"
                  >
                    Chester Contemporary newsletter here.
                  </a>
                </p>
                <h2>Chester Contemporary Fringe 2023</h2>
                <p>
                  The Chester visuals arts scene features artists and creatives
                  from all over the city making work across different media in a
                  variety of locations and spaces. If you have a visual arts
                  event, workshop or exhibition planned for Chester between 22
                  September to 1 December you could be a part of the Chester
                  Contemporary Fringe, which is open to everyone. You can
                  include a Chester Contemporary Fringe logo with publicity for
                  your event. These will be available to download from this page
                  from early September. You can also tag your events with the
                  hashtag, #CCfringe.
                </p>
                <h2>Job opportunities</h2>
                <p>
                  Any job opportunities with Chester Contemporary will be
                  advertised here.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetInvolved;
