import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/f3638f91a7b64475fdcc76436af16ed3.jpg"
          altText="alt text"
          credit="Image: How to Work Better, 1991. New York. © Peter Fischli David Weiss."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Peter Fischli David Weiss</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    How to Work Better, 1991
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    The classic manifesto for a better working life
                  </p>
                  <p>
                    This seminal work from the ground-breaking collaboration of
                    Swiss artists Peter Fischli and David Weiss is being shown
                    at full scale in the UK for the first time at Chester
                    Contemporary, only ever having been previously shown in
                    Zurich, Mexico City, New York (Guggenheim) and Okayama,
                    Japan.
                  </p>
                  <p>
                    <em>How to Work Better</em> is a 10-point list of humorously
                    simple statements which capture Peter Fischli and David Weiss’s genius of
                    making the everyday extraordinary, blurring the boundaries
                    between high and low art. The text was taken from a sign the
                    artists saw in a ceramic factory in Thailand more than 30
                    years ago which was designed to improve worker morale and
                    productivity. Now, it comes all the way to Chester and is,
                    ironically, set against the backdrop of an empty city centre
                    office building.
                  </p>
                  <p>
                    The monumental quality of <em>How to Work Better</em> stops
                    us in our tracks, inviting us to take time out from the
                    cacophony of modern digital life to think about the very
                    nature of work: just what is the essence of what we do and
                    how we do it? How do we fit into today’s systems of
                    productivity? What are today’s expectations for work? And
                    ultimately how can we live better?
                  </p>
                  <p>
                    Pause a moment, be inspired, make up your own rules.
                    #Howtoworkrules
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      Side of{' '}
                      <strong>
                        The Forum Building, Exchange Square, Chester, CH1 2EY
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>Can be viewed 24/7 from street level</p>
                  </Typography>
                  <IconLink align="top" content="7" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>
                      Peter Fischli (b. 1952) and the late David Weiss
                      (1946–2012)
                    </strong>{' '}
                    began collaborating in 1979 and have created a significant
                    body of work, rearranging and manipulating their experiences
                    of daily life into new and unexpected forms. Using various
                    mediums including unfired clay, rubber, photography, and
                    video, Fischli and Weiss have playfully fused the two ends of
                    high and low art over the course of their three decades of
                    collaboration.
                  </p>
                  <p>
                    <em>
                      How to Work Better is part of Chester Contemporary’s Core
                      Artist programme curated in conjunction with the Emerging
                      Artist programme by artistic director, Ryan Gander.
                    </em>
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
