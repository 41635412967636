import React from 'react';

import { Outlet } from 'react-router-dom';

import './styles/App.css';
import './styles/Theme.css';
// import './Normalize.css';

import { Header } from './components/header';
import { Footer } from './components/footer';

function App() {
  return (
    <div className="app">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
