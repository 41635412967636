import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
import { Hero } from '../components/Hero';

console.log(responsiveGrid, typography);

export function Events(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="section_grey">
        <Hero
          imgSrc="/content-images/f3638f91a7b64475fdcc76436af16ed3.jpg"
          altText="alt text"
        />
      </div>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>What's on</h1>
                <p className="typography_heading-2">
                  Explore Chester Contemporary. 22 September - 1 December, 2023
                </p>
                <p>
                  Discover work on Chester's streets, on its unique Rows, in
                  different spaces and places across the city. All artworks are
                  free to visit, and there's no need to book. Take a walk, and
                  see what you can find.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Artworks"
                  blurb="Chester Contemporary, curated by Ryan Gander, serves up a mouthwatering selection of international and emerging artists."
                  image="/content-images/38c54f387ee2722b2a15c527d852cbfb.jpg"
                  href="/artworks"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Schools' programme"
                  blurb="Artists have worked with Chester primary schools to create a trail of work for spaces and places around Chester city centre."
                  image="/content-images/2fca60a759745e499dfdc000bcfbeec1.jpg"
                  href="/schools"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Walks + talks + workshops"
                  blurb="A regularly updated programme of walks, talks and workshops to accompany Chester Contemporary."
                  image="/content-images/6759fdc0fead4f79f072dd6e83640e2c.jpg"
                  href="/walkstalksworkshops"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Events;
