import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/weetman.jpg"
          altText="alt text"
          credit="Image: © Claire Weetman."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Claire Weetman & Mickle Trafford Village School</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  The City Unfolds
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Chester’s city centre is characterised by its secret
                    passageways, hidden staircases, buildings on multiple levels
                    and interesting places to be discovered. Year 5 pupils from
                    Mickle Trafford Village School have shared some of their
                    favourite places and studied the architecture of the city
                    with artist Claire Weetman to create artist-book sculptures
                    combining paper folding techniques, printing and poetry.
                  </p>
                  <p>
                    Inspired by both Claire’s artist-book practice and Unfurled,
                    a University of Chester exhibition at the Grosvenor Museum
                    (which ran until 2 July), the class have explored how to use
                    the text, images and storytelling that can be found in
                    books. They’ve combined these book-making elements to create
                    their own sculptural artwork that reminds us of places in
                    the city, including Chester Cathedral, the Rows, the Walls,
                    dance and musical performances, the sound of food being
                    served at the new market, and their top tips for the best
                    pancakes in Chester! Their work can be seen in the display
                    case outside Waterstones on Eastgate Row.
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils of Mickle Trafford Village
                      School, Mickle Trafford, Cheshire.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>In the display case outside</p>
                    <p>
                      <strong>
                        Waterstones,14 Eastgate St Row South, Chester, CH1 1LF
                      </strong>
                    </p>
                    <p>
                      <strong>Free</strong> – no need to book Can be seen 24/7
                    </p>
                    <p>
                      <strong>Access</strong> to Eastgate Row South is through
                      the Grosvenor Shopping Centre. More info on access to the
                      Grosvenor Shopping Centre and the Rows here.
                    </p>
                  </Typography>
                  <IconLink align="top" content="6" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Claire Weetman</strong> is an artist who makes
                    drawings, prints, installations and public interventions
                    that visualise how we navigate through spaces, connect with
                    others and observe the traces of things that are difficult
                    to see. Claire is based in St Helens, Merseyside where she
                    works intensively with artists and communities, being
                    passionate about developing opportunities in the arts
                    outside of major cities. She has had residencies in
                    Shanghai, Istanbul, Linz and Stuttgart and has created work
                    for The Bluecoat, Heart of Glass, Castlefield Gallery,
                    Airspace Gallery, The Harris, METAL and Arts Council
                    England. Claire has worked extensively as an
                    artist-in-education, most recently for Cultured, St Helens
                    LCEP, and as an artist mentor on the Pushing Boundaries
                    programme with Curious Minds and Community Arts Northwest.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="The City Unfolds"
                  // blurb="The launch of Chester Contemporary in February with Councillor Louise Gittins, and Artist Director Ryan Gander in conversation with featured artist Simeon Barclay."
                  video={`<iframe width="560" height="315" src="https://www.youtube.com/embed/_TxuMM4mXHA?si=nl72xg2CHVD5Q8zF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
