import './ContentCard.css';

interface ContentCardProps {
  variant?: string;
  title: string;
  blurb?: string;
  category?: string;
  image?: string;
  video?: string;
  href?: string;
  date?: string;
  location?: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  variant,
  title,
  blurb,
  category,
  image,
  href,
  date,
  location,
  video,
}) => {
  if (video) {
    return (
      <div
        className={`element_content-card ${
          variant && `element_content-card__${variant}`
        }`}
      >
        <span
          className="element_content-card__video"
          dangerouslySetInnerHTML={{ __html: video }}
        />
        <span className="element_content-card__text">
          {variant ? <h4>{title}</h4> : <h3>{title}</h3>}
          {blurb && <p>{blurb}</p>}
          {location && (
            <p className="typography_tight typography_paragraph_m1">
              {location}
            </p>
          )}
          {date && (
            <p className="typography_tight typography_paragraph_m1 typography_grey-900">
              {date}
            </p>
          )}
        </span>
      </div>
    );
  } else {
    return (
      <a
        href={href}
        className={`element_content-card ${
          variant && `element_content-card__${variant}`
        }`}
      >
        <span
          className="element_content-card__image"
          style={{ backgroundImage: `url("${image}")` }}
        >
          <span className="element_content-card__category">{category}</span>
        </span>
        <span className="element_content-card__text">
          {variant ? <h4>{title}</h4> : <h3>{title}</h3>}
          {blurb && <p className="element_content-card__blurb">{blurb}</p>}
          {location && (
            <p className="typography_tight typography_paragraph_m1">
              {location}
            </p>
          )}
          {date && (
            <p className="typography_tight typography_paragraph_m1 typography_grey-900">
              {date}
            </p>
          )}
        </span>
      </a>
    );
  }
};
