import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/sarahgall.jpg"
          altText="alt text"
          credit="Image: © Sarah Gallagher-Hayes."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>
                    Sarah Gallagher-Hayes & Guilden Sutton C of E Primary School
                  </h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Willow Horse
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    The pupils of Guilden Sutton Primary have worked with artist
                    Sarah Gallagher-Hayes over a series of workshops in school
                    to create a beautiful life-size willow weave sculpture of a
                    Roman horse and chariot which can be found in the tranquil
                    churchyard at St Mary’s Creative Space in Chester city
                    centre.
                  </p>
                  <p>
                    Taking inspiration from the way soldiers bravely broke the
                    ranks of the enemy with their powerful horses, as well as
                    from the noise their wheels must have made, many of the
                    techniques the children have explored were actually used in
                    Roman times to form shelters, baskets and functional
                    frameworks.
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils of Guilden Sutton Church of
                      England Primary School, Guilden Sutton, Chester.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      In the churchyard of{' '}
                      <strong>
                        St Mary’s Creative Space,St Mary’s Hill,Chester, CH1 2DW
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> – no need to book Can be seen 24/7
                    </p>
                    <p>
                      <strong>Access</strong> The artwork can be viewed through
                      the gates which are open for events in St Mary’s. The
                      churchyard is up some steps from the pathway.
                    </p>
                  </Typography>
                  <IconLink align="top" content="4" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Sarah Gallagher-Hayes</strong> trained in sculpture
                    at Southampton University and at the world famous Accademia
                    di Belle Arti in Venice. Her career began almost immediately
                    after graduating in 1998 when she won her first commission
                    from Kew Gardens, to create a willow lion to represent The
                    Lion, The Witch and The Wardrobe’s Aslan. She also had a
                    three-month residency as Cheshire County Council’s very own
                    willow sculptor, creating site-specific work and running
                    workshops.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
