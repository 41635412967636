import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/1de9eb1d804a24e5764bb41ab25d3ea4.jpg"
          altText="alt text"
          credit='Image: The Start of All Imaginings, 2023. © Patricia Mackinnon-Day.'
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Patricia Mackinnon-Day</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    The Start of All Imaginings, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    Illuminating the rich vibrancy of local markets
                  </p>
                  <p>
                    A series of short films exploring the personal journeys of
                    nine Chester market traders,{' '}
                    <em>The Start of All Imaginings</em> has been developed
                    through a series of visits that MacKinnon-Day made to
                    Chester over the course of 2022 and 2023. She embedded
                    herself within the city’s old market, filming, observing,
                    listening and forming relationships with the traders,
                    allowing her to illuminate the realities of their everyday
                    experience. The subsequent films explore a range of personal
                    insights around migration, camaraderie, well-being, tastes,
                    tradition, community, food origins – and even a love story.
                  </p>
                  <p>
                    <em>The Start of All Imaginings</em> shows how markets act
                    as vibrant social spaces in a variety of ways for different
                    social groups; how their unique strength lies in their
                    community, and their embrace of social inclusion and care
                    for others – characteristics that are vividly brought to
                    life in these films.
                  </p>
                  <p>
                    The films will be available to view at Lache Library on
                    Lache Park Avenue (corner of Cliveden Rd), south west of
                    Chester city centre. Call 01244 973974 for more information.
                  </p>
                  <p>
                    Also at Great Boughton Library, Green Lane, in the eastern
                    suburb of Vicar's Cross. Call 01244 973913 for more
                    information.
                  </p>
                  <br />
                  <br />
                  <p>
                    <em>
                      The Start of All Imaginings is currently also showing at
                      Time Space Existence, the European Cultural Centre’s
                      architecture biennial in Venice, until 27th November 2023.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Lache Library, Lache Park Ave, Chester, CH4 8HR
                      </strong>
                    </p>
                    <p>
                      <strong>Open</strong> Mon & Thurs 9.30am-1pm, 2-7pm; Tues
                      & Fri 9.30am-1pm, 2-5pm; Weds closed; Sat 9.30am- 1pm.
                    </p>
                    <p>
                      <strong>Access</strong> The library is accessible. On
                      street parking is available close to the library. The
                      library is can be reached from the city centre by regular
                      bus services 7, 8 and 16.
                    </p>
                    <p>
                      <strong>
                        Great Boughton Library, Green Lane, Vicars Cross,
                        Chester, CH3 5LB
                      </strong>
                    </p>
                    <p>
                      <strong>Open</strong> Mon & Thurs 9.30am-1pm, 2-7pm; Tues,
                      Weds & Fri 9.30am - 1pm, 2-5pm; Sat 9.30am-1pm
                    </p>
                    <p>
                      <strong>Access</strong> The library is accessible. There
                      is parking behind the library. The number 7 city bus stops
                      outside the library.
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                  </Typography>
                  <IconLink align="top" content="12" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Patricia Mackinnon-Day (b, Glasgow)</strong> gained
                    a Double First-class honours with commendation in Fine Art
                    at Liverpool School of Art, Media and Design before
                    completing an MA at the Royal College of Art, London,
                    followed by a Doctorate at Liverpool John Moores University
                    where she has recently been awarded an Honorary Research
                    Fellowship. She now lives and works in North Wales. Her work
                    has been exhibited at venues throughout the UK and
                    elsewhere. Specialising in installation, it has generated a
                    series of socially-engaged gallery installations and
                    site-specific corporate and public projects both nationally
                    and internationally.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      The Start of All Imaginings has been created with
                      communities in Chester as part of the development of
                      Chester Contemporary.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
