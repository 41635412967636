import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function PastProjects(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>Past projects</h1>
                <p>
                  The preparation for the first edition of Chester Contemporary
                  has included the creation of a wide range of projects and
                  events with artists and diverse communities across the city.
                  Embracing schools, colleges, local neighbourhoods, the
                  University of Chester and a variety of venues, these projects
                  have paved the way for Chester Contemporary’s exciting
                  programme.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Lowri Evans"
                  blurb="The Feast"
                  image="/content-images/2c7f54444a40a70eae70de5ce4877b46.jpg"
                  href="#"
                  location="Lache"
                  date="May 2023 "
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Heinrich & Palmer"
                  blurb="Once a Desert"
                  image="/content-images/cbfba91ee8de8453bed2990f438d8121.jpg"
                  href="#"
                  location="Chester Cathedral"
                  date="March 2022"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Neuza Morais"
                  blurb="The Missing Building"
                  image="/content-images/8ed9cecc048df6a2dc7e7dfc4b2e03b0.jpg"
                  href="#"
                  location="Various sites"
                  date="March 2022"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Ian Prewitt"
                  blurb="Create, Share, Collect"
                  image="/content-images/082fe7f2bc7a84727ef21e81196f916f.jpg"
                  href="#"
                  location="Various sites"
                  date="March, 2021 & 2022"
                />
              </div>
              {/* row */}
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Unfurled: Encounters with Artists' Books"
                  image="/content-images/d6e866fd152d97c050f433f8eaa2b2b1.jpg"
                  href="#"
                  location="Grosvenor Museum"
                  date="June 2023"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Lost, Found, Given - Stored, Shown, Seen: Artists' responses to the West Cheshire Museums' Collections"
                  image="/content-images/ad33c46e72c879d7beb3882823fc119c.jpg"
                  href="#"
                  location="Grosvenor Museum"
                  date="15 July-3 September, 2023"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Suzanne St Clare"
                  blurb="Picturing High Streets. With Chester Traders @Chester"
                  image="/content-images/da2af7cfbf8ba97badafcbede8bdbd6a.jpg"
                  href="#"
                  location="Castlefield Gallery New Art Spaces: Chester, Grosvenor Shopping Centre"
                  date="28 July-1 September, 2023"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Ciara Leeming"
                  blurb="Picturing High Streets. With The Spider Project @Chester"
                  image="/content-images/bccdc8d0ba8ac8a322600a69e683bda3.jpg"
                  href="#"
                  location="Castlefield Gallery New Art Spaces: Chester, Grosvenor Shopping Centre"
                  date="28 July-1 September, 2023"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  variant="past"
                  title="Suzanne St Clare"
                  blurb="Reclaim. With residents of Chester @Chester"
                  image="/content-images/50f754b6da670869e792473d13b8fe78.jpg"
                  href="#"
                  location="Castlefield Gallery New Art Spaces: Chester, Grosvenor Shopping Centre"
                  date="28 July-1 September, 2023"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default PastProjects;
