import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Home(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <Carousel />
      <main className="foundation_spacing_section">
        <div className="feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-start="m-2"
                data-grid-size="s-12 m-10 l-8"
              >
                <Typography>
                  <h1>Chester Contemporary</h1>
                  <p className="typography_heading-2">
                    Friday 22 September - Friday 1 December, 2023
                  </p>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item typography_paragraph"
                data-grid-start="m-2"
                data-grid-size="s-12 m-10 l-08"
              >
                <Typography>
                  <p>
                    Chester Contemporary is a new visual arts event curated by
                    artist Ryan Gander. For the Contemporary, international and
                    Chester-based artists, emerging talent, and the city’s
                    people have been invited to make and show work for Chester’s
                    unique places and spaces, inspired by the theme ‘Centred on
                    the Periphery’.
                  </p>
                  <p>
                    Chester Contemporary brings new art to the city’s historic
                    streets. Dive in and discover more.
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="What's on"
                  blurb="All Chester Contemporary events in one place. With more than 20 artworks, a schools’ trail and a programme of walks, talks and workshops, your CC experience starts here."
                  image="/content-images/f3638f91a7b64475fdcc76436af16ed3.jpg"
                  href="/events"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Chester Contemporary map"
                  blurb="Chester Contemporary is a walking biennial, with work popping up across this most beautiful and compact of cities. Find your way around the artworks with this handy map."
                  image="/content-images/f11f8f6cacee754f3e954d01b2ee96ac.png"
                  href="/map"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Artworks"
                  blurb="Explore the work of Chester Contemporary’s eclectic and exciting mix of artists and artworks across the city’s spaces and places."
                  image="/content-images/38c54f387ee2722b2a15c527d852cbfb.jpg"
                  href="/artworks"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Your visit"
                  blurb="Plan your visit to Chester – how to get here and and how to get around. Plus useful access information and links for your visit."
                  image="/content-images/ChesterClock.png"
                  href="/visit"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Past projects"
                  blurb="Find out what events and projects have happened in the lead up to Chester Contemporary."
                  image="/content-images/cbfba91ee8de8453bed2990f438d8121.jpg"
                  href="/PastProjects"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-third"
              >
                <ContentCard
                  title="Get involved"
                  blurb="Find out how you can get involved in Chester Contemporary, from volunteering to networking and skills development opportunities.  "
                  image="/content-images/Easels.png"
                  href="/GetInvolved"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;
