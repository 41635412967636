import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/rachelgreen.jpg"
          altText="alt text"
          credit="Image: © Rachel Green."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Rachel Green & Cherry Grove Primary School</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Merchant Vessels
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Artist Rachel Green and students from Year 4 at Cherry Grove
                    Primary School were inspired by Chester’s history as a port,
                    to create Merchant Vessels. In medieval times, before the
                    River Dee silted up, ships would come to the city’s port to
                    offload cargo from all over Europe. The city would have
                    bustled with traders from these countries as they did
                    business with Chester’s merchants and guilds.
                  </p>
                  <p>
                    Together, Rachel and the children have created 15 sculptural
                    models of traditional merchant ships using card, dowelling,
                    gummed tape, fabric, wire and acrylic paint. Because the
                    ships are suspended by thin wire, any air movement seems to
                    make them float!
                  </p>
                  <p>
                    You can find the ships on Chester’s historic Rows in the
                    display case outside Cavani and at the Julie Colclough
                    Gallery.
                  </p>
                  <p>
                    <em>Thanks to the staff and pupils of{' '}
                    <a
                      href="http://www.cherrygrove.cheshire.sch.uk/"
                      target="_blank"
                    >
                      Cherry Grove Primary School, Boughton, Chester
                    </a>
                    .</em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Cavani, 16, Eastgate Row South, Chester, CH1 1LF
                      </strong>
                    </p>
                    <p>
                      <strong>Access</strong> There is level access to Eastgate
                      Row South through the Grosvenor Shopping Centre.{' '}
                      <a
                        href="https://www.accessable.co.uk/cheshire-west-and-chester-council/cheshire-west-and-chester/access-guides/the-rows#2b43c933-f7c6-0145-ad20-b1d2c1332eeb"
                        target="_blank"
                      >
                        More Rows access details here
                      </a>
                      .
                    </p>
                    <p>
                      <strong>
                        Julie Colclough Gallery,59 Watergate Row South,Watergate
                        St, Chester, CH1 2LE
                      </strong>
                    </p>
                    <p>
                      <strong>Access</strong> is off Bridge Street on Pierpoint
                      Lane via a steep ramp.{' '}
                      <a
                        href="https://www.accessable.co.uk/cheshire-west-and-chester-council/cheshire-west-and-chester/access-guides/the-rows#2b43c933-f7c6-0145-ad20-b1d2c1332eeb"
                        target="_blank"
                      >
                        More Rows access details here
                      </a>
                      . It can also be accessed from the back of the Rows at
                      Weaver Street.{' '}
                      <a
                        href="https://www.accessable.co.uk/cheshire-west-and-chester-council/cheshire-west-and-chester/access-guides/watergate-street-watergate-rows-north-and-south#2d73e436-69b9-964d-a6e2-86903ce2e485"
                        target="_blank"
                      >
                        More details here
                      </a>
                      .
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to bookCan be viewed 24/7
                    </p>
                  </Typography>
                  <IconLink align="top" content="1" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Rachel Green</strong> has an Embroidery Degree from
                    Manchester Metropolitan University. She is a mixed media
                    artist and loves working with a variety of materials,
                    designing both 2D and 3D projects from sculptures and
                    textiles to painting and print and everything in between.
                    She has exhibited around the country and was a high school
                    artist-in-residence. She has also worked for the Cheshire
                    Arts Advisor and West Cheshire College designing and
                    teaching art workshops to both adults and primary and
                    secondary students. She has been teaching Raising
                    Achievement weekends and art workshops for Edsential for
                    many years. In the last three years she has set up her own
                    applied arts subscription, designing kits for people to be
                    able to make their own art at home.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>{' '}
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
