import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/DIS-ARM.jpg"
          altText="alt text"
          credit="Image: DISARM, 2023. © Fiona Banner aka The Vanity Press."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Fiona Banner aka The Vanity Press</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    DIS<em>ARM</em>, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    An enigmatic, multi-layered installation
                  </p>
                  <p>
                    Set in a once busy fashion store, DIS<em>ARM</em> is infused with a
                    contradictory sense of desire, conflict and lost aspiration,
                    wittily referencing the ‘runway’ as a site for aircraft as
                    well as a theatrical fashion space. Drawing on the military
                    history written into Chester’s fabric, it suggests conflict
                    is neither distant nor past.
                  </p>
                  <p>
                    Upended helicopter blades stand in as mannequins which
                    exhibit Banner’s collection of military aircraft-inspired
                    costumes, while old Topshop dummies are taken apart and
                    repurposed as props. A short film shows an arm emblazoned
                    with DIS<em>ARM</em> hurled into the blue sky. Across the shop
                    floor, the grandiose, seductive soundtrack of{' '}
                    <em>Pranayama Organ</em> plays out. In this film, inflatable
                    military decoy aircraft enact a strange unrequited desire
                    for intimacy not conflict; figures, at once birdlike, human
                    and automaton, dance around each other in an absurd ritual
                    of courtship and combat.
                  </p>
                  <p>
                    Falcon, an authentic decoy fighter plane is pressed into the
                    shop window, inflating and deflating – an absurd breathing
                    creature, as much animal as state-of-the-art fighter.
                  </p>
                  <p>
                    The now defunct Topshop brand is redolent of an age of
                    consumer innocence; the once brightly mirrored space is
                    transformed into a darkened theatre for a very different
                    type of yearning.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 23 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                      Castlefield Gallery New Art Spaces: Chester (The former Topshop unit), Grosvenor Shopping Centre, Eastgate St, Chester, CH1 1LE
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Open</strong> Weds-Sun, 11am - 4pm
                    </p>
                    <p>
                      <strong>Access</strong> The unit is accessible from the
                      entrance to the Grosvenor Shopping Centre at pavement
                      level on Eastgate Street. The exhibition is step-free.
                      More access details here.
                    </p>
                  </Typography>
                  <IconLink align="top" content="2" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>
                      Fiona Banner aka The Vanity Press (b. 1966, Merseyside,
                      UK)
                    </strong>{' '}
                    explores gender, language and conflict through a range of
                    mediums, including drawing, sculpture, performance, and the
                    moving image. Language and publishing are at the heart of
                    her practice and Banner’s attitude is at once playful and
                    often performative. Her work centres on the problems and
                    possibilities of language, both written and metaphorical. In
                    1997, Banner started her own publishing imprint The Vanity
                    Press, with her monumental The Nam. She has since published
                    many works, as books, sculptural objects or performances. In
                    2009, she issued herself an ISBN number and registered
                    herself as a publication under her own name, a sort of
                    self-portrait as a book.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    
                      DIS<em>ARM is part of Chester Contemporary’s Core Artist
                      programme curated in conjunction with the Emerging Artist
                      programme by artistic director, Ryan Gander.
                    </em>
                  </p>
                  <p>
                    <em>
                      Thanks to Castlefield Gallery for their support of{' '}</em>DIS<em>ARM</em>.
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
