import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/2fca60a759745e499dfdc000bcfbeec1.jpg"
          altText="alt text"
          credit="Image: © Clare Owens."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>
                    Clare Owens & St Werburgh's & St Columba's Catholic Primary
                    School
                  </h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    The Journey
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Artist Clare Owens, Year 3 and 4 pupils, and their teacher,
                    Jake McCallum, went on a walking journey from their school
                    in Hoole to Chester’s Rows. Along the way they talked about
                    all kinds of things inspired by their journey, including the
                    historic scenery and what living in Chester means to them.
                    The journey took them past some of the most iconic views of
                    Chester, along the Shropshire Union Canal, past The Lead
                    Shot Tower (the oldest of the three remaining shot towers in
                    the UK), into Grosvenor Park to picnic with the squirrels
                    and ducks, and finally to Bridge Street Row. Over the course
                    of the journey the children observed and sketched using
                    charcoal and graphite, and so became ‘tourists’ in their own
                    city!
                  </p>
                  <p>
                    The resulting work, which represents their magical journey,
                    uses a range of 2D and 3D mixed-media approaches and can be
                    seen at Pictura Studios on Eastgate Row.
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils of St Werburgh’s and St Columba’s Catholic Primary School, Hoole, Chester, who have been such fun to work alongside.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      Inside at{' '}
                      <strong>
                        Pictura Studios, 5 Eastgate Row North, Chester, CH1 1LQ
                      </strong>
                    </p>
                    <p>
                      <strong>Free</strong> – no need to book
                    </p>
                    <p>
                      <strong>Open</strong> Weds & Fri 10am-8.30pm; Thurs
                      10am-6pm; Sat 10am-5pm; Sun 11am-5pm
                    </p>
                    <p>
                      <strong>Access</strong> The work is displayed upstairs.
                    </p>
                  </Typography>
                  <IconLink align="top" content="8" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Clare Owens</strong> is a Chester-based
                    teacher/creative practitioner. She has taught across all the
                    educational sectors from primary to higher education as well
                    as teaching in other settings, eg communities, prisons, as
                    well as arts centres including Storyhouse, Theatre Porto,
                    and Edsential residential centres. She feels that working
                    creatively, in particular with primary aged children, should
                    be fun, enable learning and exploration, be challenging and
                    yet provide a safe place to be mindful and make individuals
                    feel great about what they create. She has an MA in Fine
                    Art, working predominantly in textiles and sculptural art.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
