import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/1b81f9c0f1b2adeee582ec71d0aec5a6.jpg"
          altText="alt text"
          credit="Image: Still from COAL, 2021. © Elizabeth Price."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Elizabeth Price</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    HORSETAILS, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    A new performance work from the Turner-prize winning artist
                  </p>
                  <p>
                    The magnificence of Chester Cathedral is the backdrop for
                    Turner-prize winning Elizabeth Price’s new performance work
                    for Chester Contemporary.
                  </p>
                  <p>
                    HORSETAILS uses both singing and speaking voices accompanied
                    by body percussion to explore a geological history of the UK
                    profoundly impacted by human settlement, and by years of
                    mining and extraction – the processes and decline of which
                    still influence the realities of towns and villages up and
                    down the country.
                  </p>
                  <p>
                    By reciting the names of these places, the audience is asked
                    to recall the extraordinary ancient organic lifeforms, such
                    as equisetails or horsetails, that once thrived across this
                    land up to 300 million years ago. And by using song to
                    recite the unfamiliar, but fascinating names of these
                    ancient forms of flora and foliage which were extinguished
                    at the end of the carboniferous period, HORSETAILS also
                    acknowledges how our lives have been impacted by the
                    powerful, social and economic historical forces of our own
                    time.
                  </p>
                  <p>
                    The work will begin with a simple performance of a libretto
                    by the cathedral’s Nave Choir on Sunday 24 September.
                    Thereafter, the work will be nurtured and performed weekly
                    by four performers, climaxing on Sunday 26 November when the
                    full choral piece will be revealed.
                  </p>
                  <p>
                    <em>
                      This extended choral work is developed from the script of
                      an earlier video COAL, created in 2021. The work’s
                      libretto will be available in a specially-published
                      collectable ‘zine available for free at the cathedral
                      throughout the Contemporary.
                    </em>
                  </p>
                  <p>
                    <em>
                      To develop and create the performances, Elizabeth is
                      collaborating with Creative Vocal Coach, Beth Allen, and
                      Chester Cathedral Acting Assistant Director of Music,
                      Alexander Lanigan-Palotai.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sun 24 Sept - Sun 26 Nov, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Chester Cathedral, St Werburgh St, Chester, CH1 2DY
                      </strong>
                    </p>

                    <p>
                      <strong>Performances Opening performance</strong> Sun 23
                      Sept, 7pm
                    </p>
                    <p>
                      <strong>Weekly performances</strong> 12noon on Sundays: 22
                      & 29 Oct, 5, 12 & 19 Nov.
                    </p>
                    <p>
                      <strong>Full choral work</strong> Sun 26 Nov, 7pm (arrive
                      for 6.45pm)
                    </p>
                    <p>
                      <strong>FREE</strong>
                    </p>
                    <p>
                      <strong>Access</strong> to the Cathedral choir is
                      step-free from ground floor level. More access details
                      here.
                    </p>
                  </Typography>
                  <IconLink align="top" content="13" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                  <Button
                    href={
                      'https://chestercathedral.ticketsolve.com/share/1173648705'
                    }
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Elizabeth Price (b.1966, Bradford, UK)</strong>{' '}
                    creates short videos which explore the social and political
                    histories of artefacts, architectures and documents. The
                    subject matter may sometimes be focused on historic artworks
                    of great cultural significance but is more frequently
                    concerned with marginal or derogated things, and often pop
                    cultural or mass produced objects. Her video narrations draw
                    upon and satirise the administrative vernaculars of relevant
                    public and academic institutions, as well as advertising
                    copy, and the texts of private and commercial organisations.
                    In 2012, she was awarded the Turner prize for her video
                    installation The Woolworths Choir of 1979. Price attended
                    Putteridge Comprehensive School, Luton, and then studied at
                    the Royal College of Art, London and the University of
                    Leeds.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    HORSETAILS{' '}
                    <em>
                      is part of Chester Contemporary’s Core Artist programme
                      curated in conjunction with the Emerging Artist programme
                      by artistic director, Ryan Gander.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
