import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Hero } from '../components/Hero';
import { Typography } from '../components/Typography';
import { IconLink } from '../components/IconLink';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function About(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="section_grey">
        <Hero
          imgSrc="/content-images/Event.png"
          altText="alt text"
          credit="Photograph by Catharine Braithwaite"
        />
      </div>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>About</h1>
                <p className="typography_heading-2">
                  Shining a light on Chester Contemporary
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="mini-feature_white">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-size="s-12 m-08"
              data-grid-start="m-2"
            >
              <Typography>
                <h2>What is Chester Contemporary?</h2>
                <p>
                  Chester Contemporary is a major new visual arts event with a
                  core programme curated by Chester-born artist Ryan Gander OBE
                  RA. It runs from 22 September to 1 December, 2023.
                </p>
                <p>
                  The Contemporary is an ambitious international cultural event
                  that makes and shows relevant, distinctive, contemporary art
                  alongside a programme of events that respond to the city's
                  unique places and spaces, its history and its characters.
                  Chester Contemporary features work by acclaimed UK artists and
                  supports emerging artists at a pivotal point in their career
                  to make new and ambitious projects.
                </p>
                <p>
                  The Contemporary aims to transform Chester into a city that's
                  known for its visual arts scene; where world-class art can put
                  down roots in the city, developing a cultural ecology and
                  infrastructure that enables more creative practitioners - and
                  graduates - to stay and make work sustainably. You can{' '}
                  <a href="/FAQs.pdf" target="_blank">
                    download Chester Contemporary FAQs here
                  </a>
                  .
                </p>
                <p>
                  Chester Contemporary will bring the city's stories and spaces
                  to life, using Chester's unique heritage as a canvas.
                </p>
                <h4>Working with Chester's communities</h4>
                <p>
                  The Contemporary's social engagement programme has inspired
                  long-term creative relationships and partnerships between
                  artists and communities across the city. The Feast was a
                  collaboration between artist Lowri Evans and the people of
                  Lache which took place in May and artist Patricia
                  Mackinnon-Day worked with Chester's market traders to produce
                  <em>The Start of all Imaginings</em>, which can be seen as
                  part of the Contemporary programme.
                </p>
                <h4>Education & training</h4>
                <p>
                  Chester Contemporary works closely with the University of
                  Chester to support its students to find pathways into
                  successful careers in the arts. Second year graphic design
                  students used the Contemporary brand as the inspiration for a
                  project in their final term. Fashion and fashion marketing
                  students worked with the Contemporary team and Open Eye
                  Gallery in Liverpool; and students have taken up volunteering
                  opportunities, with many university staff supporting
                  initiatives, events and exhibitions.
                </p>
                <p>
                  The Contemporary has also partnered with Edsential, a CIC
                  providing quality and creative cultural experiences for
                  children, pairing artists with primary schools in Chester. The
                  artists have worked with children since May to develop work
                  inspired by the city and Chester Contemporary's programme.
                  Their work can be seen at venues on the Rows during the
                  Contemporary.
                </p>
                <p>
                  As part of the Contemporary's action research programme in
                  2020/2021, students from West Cheshire College's fine art
                  course reimagined some of Chester's best-known architectural
                  landmarks, and over lockdown sent their artwork with messages
                  of hope to residents at local care homes.{' '}
                </p>
                <h2>Our funders and partners</h2>
                <p>
                  The Contemporary would like to thank and acknowledge the
                  support of our funders, HM Government (UK Shared Prosperity
                  Fund), Arts Council England, National Lottery Heritage Fund,
                  Henry Moore Foundation, Historic England and Cheshire West and
                  Chester Council, without whom Chester Contemporary would not
                  have been possible.
                </p>
                <p>
                  Our partners help us drive the ideas and vision for the
                  Contemporary programme and have been instrumental in
                  delivering this inaugural edition. So, thank you University of
                  Chester, Storyhouse, Open Eye Gallery and Edsential for
                  everything you've done and continue to do.
                </p>
                <p>
                  The Contemporary is also grateful for the time and energy
                  generously contributed by its supporters: the Grosvenor
                  Museum, Chester Cathedral, Castlefield Gallery and Lisson
                  Gallery.
                </p>
                <p>
                  Grateful thanks also to the Grosvenor Shopping Centre, Chester
Racecourse, Recclesia and the Rows Business Collective.
                </p>
                <p>
                  Thanks also to Chester's incredible visual arts sector who
                  make this such a creative and inspiring city in which to live,
                  work and play.
                </p>
                <h2>Support us</h2>
                <p>
                  If you'd like to support Chester Contemporary to have a long
                  term impact in the city either by either sponsoring, donating
                  or supporting in kind, please contact{' '}
                  <a href="mailto:hello@chestercontemporary.org">
                    hello@chestercontemporary.org
                  </a>
                  . You could be supporting our education work, community
                  outreach or emerging artist programme to ensure that Chester
                  Contemporary goes from strength to strength.{' '}
                </p>
                <h2>Access</h2>
                <p>
                  We aim to make Chester Contemporary as accessible as possible
                  to everyone who visits. Each Chester Contemporary venue and
                  event will have its own access information which can be found
                  on the individual event pages.
                </p>
                <p>
                  The{' '}
                  <a href="https://www.accessable.co.uk/cheshire-west-and-chester-council">
                    AccessAble
                  </a>{' '}
                  guide to Chester and Cheshire West covers access details for a
                  selection of hotels, bars, restaurants and attractions in
                  Chester. You can also download a copy of{' '}
                  <a href="https://www.visitcheshire.com/dbimgs/Accessible%20Chester.pdf">
                    Chester city centre access: A Guide for Disabled People
                  </a>{' '}
                  which includes more details and maps for accessible parking,
                  access to the Rows and public toilets in Chester.{' '}
                </p>
                <h2>Contact us</h2>
                <p>
                  We'd love to hear from you if you have any ideas or thoughts
                  about Chester Contemporary. Drop us a line at{' '}
                  <a href="mailto:hello@chestercontemporary.org">
                    hello@chestercontemporary.org
                  </a>
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
