import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/busysite.jpeg"
          altText="alt text"
          credit='Image © Julia Midgley'
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Beneath the Surface, drawing from observation</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  Talk with Julia Midgley 
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Julia Midgley’s work as a reportage and documentary artist and printmaker has taken her all over
the country as well as to Chester and Cheshire.
</p>
                    <p>
This talk will focus on the work Julia has been been carrying out since the 1980s, which records excavations of Chester's Roman Amphitheatre; objects and drawings found in the archives of the Cheshire Records office; training activities at the Cheshire Yeomanry, C Division, Fox Barracks, Chester, and the many objects recorded by drawings in County Conundrum, a commission which commemorated the centenary of the then Cheshire County Council, including up to 40 items all of which are found in the county or the city of Chester. 
</p>
                    <p>
“The above projects taught me so much about the history and activities of my home county, by
scratching the surface, history revealed itself”. Julia Midgley, RWS, RE MAFA

                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 14 Oct, 3pm</strong>
                    </p>
                    <p>
                      <strong>
                        Grosvenor Museum, 27 Grosvenor Street, Chester, CH1 2DD
                      </strong>
                    </p>
                    <p>
                      <strong>£2</strong>
                    </p>
                    <p>
                      <strong>Access</strong> The Lecture Theatre is on the
                      ground floor of the Museum and is accessible via lift from
                      street level. Other floors of the Museum have no lift
                      access.
                    </p>
                  </Typography>
                  <Button
                    href={'https://www.ticketsource.co.uk/chestercontemporary/beneath-the-surface-drawing-from-observation-talk-by-julia-midgley/e-jgkmgy'}
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Julia Midgley</strong> is an artist, printmaker and documentary artist. She is the recipient of national awards for drawing, painting and printmaking. Direct observational drawing is the bedrock of her practice, specifically in her reportage work. 
                  </p>
                  {/* <p> TODO: social link</p> */}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
