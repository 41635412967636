import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/b73057c247719993453a1b9e2dfb9bac.jpg"
          altText="alt text"
          credit="Image: Places I go when I can’t sleep (Explosive plans centring from the periphery), 2023. © Ryan Gander Studio."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Ryan Gander</h1>
                  <h2 className="typography_heading-2 typography_shadow">
                    New ephemeral interventions for Chester
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    Places I go when I can’t sleep (Explosive plans centring
                    from the periphery), 2023
                  </p>
                  <p>
                    Commemorate Chester Contemporary by getting inked with an
                    original Ryan Gander tattoo at Stigma Tattoo Studio. The
                    design reproduces 'action marks’ appropriated from a Tintin
                    cartoon strip by Hergé, isolated from its original context,
                    and depicting a circular self-fulfilling expression of
                    energy.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 23 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Stigma Tattoo Studio, 7 Rufus Court, Chester CH1 2JB
                      </strong>
                    </p>
                    <p>
                      <strong>Bookings</strong> stigmatattoo.co.uk
                    </p>
                    <p>
                      <strong>Open</strong> Mon-Sat, 10am-5.30pm
                    </p>
                    <p>
                      <strong>Access</strong> Stigma Tattoo Studio is up one
                      flight of stairs.
                    </p>
                  </Typography>
                  <IconLink align="top" content="8" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    The Cestrian Ya-Ya, 2023
                  </p>
                  <p>
                    Sample a cocktail invented by Ryan Gander especially for
                    Chester Contemporary. They’ll be served at The Venetian
                    Cichetti bar in Rufus Court and Metronome Jazz Bar in
                    Godstall Lane, both in Chester city centre.
                  </p>
                  <p>
                    Just ask for the The Cestrian Ya-Ya and you’ll get a heady
                    mix of white port, brandy, cardamom and orange bitters. The
                    umbrella’s optional.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 23 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        The Venetian Cichetti bar,Rufus Court, Chester, CH1 2JW
                      </strong>
                    </p>
                    <p>
                      <strong>Open</strong> Tues & Thurs 5-11pm; Fri & Sat
                      noon-11pm; Sun 3-11pm
                    </p>
                    <p>
                      <strong>Access</strong> Level access into the venue
                    </p>
                  </Typography>
                  <IconLink align="top" content="8" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Metronome Jazz Bar, 12 Godstall Lane, Chester, CH1 1LN
                      </strong>
                    </p>
                    <p>
                      <strong>Open</strong> Thurs 7-11pm; Fri 6-11pm; Sat
                      6pm-midnight; Sun 2-8pm
                    </p>
                    <p>
                      <strong>Access</strong> Some steps into the venue
                    </p>
                  </Typography>
                  <IconLink align="top" content="8" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    Centred on the periphery, 2023
                  </p>
                  <p>
                    Flying above The Lady Diana boat on the River Dee is a white
                    surrender flag representing the outlines of several flags
                    including the Union of South American Nations; the Flag of
                    Earth; HM Coast Guard; the Principality of Sealand; NASA;
                    Jolly Roger Pirate; United Galactic Federation; the Flag of
                    Animal Farm; Europe; the Progress Pride Flag; the Dune
                    Inspired Choam Flag; Japan; Australia, and Germany.
                  </p>
                  <p>A conglomerate of optimism and collectivity.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept – Fri 1 Dec</strong>
                    </p>
                    <p>
                      <strong>
                        The Lady Diana, The Groves, Chester CH1 1SZ
                      </strong>
                    </p>
                    <p>
                      <strong>Free</strong> – no need to book
                    </p>
                    <p>Can be viewed 24/7 from ground level</p>
                  </Typography>
                  <IconLink align="top" content="8" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Ryan Gander OBE RA (b.1976, Chester, UK)</strong> is
                    Artist Director of Chester Contemporary 2023, who lives and
                    works in Suffolk. He studied at Manchester Metropolitan
                    University, UK, the Rijksakademie van Beeldende Kunsten,
                    Amsterdam, NL and the Jan van Eyck Akademie, Maastricht, NL.
                    Gander has been a Professor of Visual Art at the
                    Universities of Huddersfield and Suffolk and holds an
                    honorary Doctor of the Arts at the Manchester Metropolitan
                    University and the University of Suffolk. In 2017 he was
                    awarded an OBE for services to contemporary arts. In 2019 he
                    was awarded the Hodder Fellowship at Princeton University.
                    In 2022, he was made RA for the category of Sculpture.
                  </p>
                  <p>
                    He has established an international reputation through
                    artworks that materialise in many different forms, ranging
                    from sculpture, apparel and writing, to architecture,
                    painting, typefaces, publications and performance.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
