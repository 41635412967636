import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/oliviaglas.jpg"
          altText="alt text"
          credit="Image: © Olivia Glasser."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Olivia Glasser & Capenhurst CE Primary School</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Bumpee Smoov Bumpee
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Artist Olivia Glasser and the pupils of Capenhurst Primary
                    School have used tactile materials such as clay, print and
                    card to explore the textures found in the architecture of
                    the Rows, and sensations people experience when they eat
                    food.
                  </p>
                  <p>
                  The students have imagined and created recipes and artwork for fantastic cakes inspired by the
colours and textures found on the Rows. Showcasing their print-making and drawing skills, 2D and
3D artwork will displayed at the Rainbow Tea Rooms, giving an insight into the recipe-making
process.
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils of Capenhurst CE Primary
                      School, Chester.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>
                        Fri 22 Sept – Fri 1 Dec, 2023 Rainbow Tea Rooms 28
                        Bridge St, Chester, CH1 1NQ
                      </strong>
                    </p>
                    <p>
                      <strong>
                        Check with Rainbow Tea Rooms for dates, 01244 257602
                      </strong>
                    </p>
                    <p>
                      <strong>Open</strong> Mon-Fri 9.30am-5pm; Sat 9am-6pm; Sun
                      10am-5pm
                    </p>
                    <p>
                      <strong>Free</strong> – no need to book
                    </p>
                    <p>
                      <strong>Access</strong> level access into the venue
                    </p>
                  </Typography>
                  <IconLink align="top" content="3" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Olivia Glasser</strong> is an artist, educator and
                    facilitator, who is driven by the question of how art and
                    creativity can be embedded into society. She has taught on
                    art and design programmes in higher education for over ten
                    years and currently teaches critical and contextual studies
                    programmes at the School of Arts & Media at the University
                    of Salford. Olivia thrives on creating opportunities for
                    children and young people to collaborate with artists on
                    co-created, ambitious work, which emerge through problem
                    solving, play and engaging with unconventional materials.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
