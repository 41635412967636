import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/neuza.jpg"
          altText="alt text"
          credit="Image: © Neuza Morais."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>
                    Neuza Morais & Grosvenor Park Academy /Dee Point Primary
                    School
                  </h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    The Missing Story
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Following on from her Missing Building project, which asked
                    children and families in Chester to create the building they
                    thought was missing in their city, architect and creative
                    educator Neuza Morais has worked with the pupils of two
                    schools, Grosvenor Park Academy and Dee Point Primary School
                    to create The Missing Story. The project explored the
                    history of Chester's Rows with the children making up their
                    own narrative around these unique places.
                  </p>
                  <p>
                    Together, Neuza and the children from each school have
                    created 3D conceptual models of the Rows which will be on
                    display at a venue to be announced.
                  </p>
                  <p>
                    Each school has also written and illustrated a story which
                    takes place on the Rows. The stories will appear in a
                    hardcover book and a short film, and 1,000 postcards will be
                    distributed to primary schools across the UK and
                    internationally promoting the distinctive and unique
                    features of Chester Rows.
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils of Grosvenor Park Academy,
                      Chester and Dee Point Primary School, Blacon, Chester.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>Chester Visual Arts Gallery
                      (Former H&amp;M unit)
                      Grosvenor Shopping Centre
                      Eastgate St, Chester, CH1</strong>
                    </p>
                    <p>
                      <strong>Free</strong> – no need to book
                    </p>
                    <p><strong>Access</strong> Chester Visual Arts Gallery has level access from the shopping centre.</p>
                  </Typography>
                  <IconLink align="top" content="9" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Neuza Morais</strong> is an Architect, Community
                    Educator and researcher based in Chester. She currently
                    holds multiple roles in the University of Chester including
                    Visiting Lecturer in the Department of Art & Design,
                    Language Facilitator in the Department of Languages and
                    Cultures, and Researcher for the Department of Music, Media
                    and Performance. Neuza is also a doctoral student at the
                    University of Chester, where her research interests lie in
                    the intersection of Community, Belonging and the Built
                    Environment, which she explores through Art-Based Research
                    (ABR) techniques, including film and music. Outside higher
                    education, Neuza works as an Arts Facilitator in Ty Pawb
                    (Wrexham) and is the founder of the Kitaya Project.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>{' '}
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="Mystery on the Rows by Dee Point Primary School for Chester Contemporary 23"
                  // blurb="The launch of Chester Contemporary in February with Councillor Louise Gittins, and Artist Director Ryan Gander in conversation with featured artist Simeon Barclay."
                  video={`<iframe width="560" height="315" src="https://www.youtube.com/embed/o9RHdiDh65Q?si=WrJ9cV8NkScLaWBD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="The Broken Bridge by Dee Point Primary School for the Chester Contemporary 23"
                  // blurb="Chester Contemporary artist Tim Foxon explains his No Look Portraits – watch him in action!"
                  video={`<iframe width="560" height="315" src="https://www.youtube.com/embed/RLPVlpUPsQk?si=xpauibi75dkscxpS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="Secrete behind the Rows by Grosvenor Park Academy School for The Chester Contemporary 23"
                  // blurb="Artist Patricia Mackinnon-Day introduces her work The Start of all Imaginings at the launch of Chester Contemporary."
                  video={`<iframe width="560" height="315" src="https://www.youtube.com/embed/T7niq-OFKdw?si=jYMsd_nEYhC7TWnN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
