import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/38c54f387ee2722b2a15c527d852cbfb.jpg"
          altText="alt text"
          credit="Image: Them Over There, 2023. © Simeon Barclay."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Simeon Barclay</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Them Over Road, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    Exploring a city’s potential for transformation
                  </p>
                  <p>
                    How does a city begin to unpick and understand its history
                    and identity? How does the past inform the future? In this
                    new work for Chester Contemporary, Barclay blends neons with
                    altered and found objects to explore a city’s
                    transformational potential, from heritage playground to
                    modern setting. Can the two co-exist? How does the city
                    create a life in the body it wants to become, reclaiming its
                    former glory or reinventing itself without stirring an inner
                    turmoil that reflects a crisis of identity?
                  </p>
                  <p>
                    <em>Them Over Road</em> fuses historic Chester with an
                    anxious modern Britain and asks how a focus on ‘heritage’
                    can often undermine a city’s aspirations, forcing it to
                    continually push to define itself in the light of the North
                    West’s other great cities. This dynamic is suggested by the
                    incorporation of neons, which point towards Chester’s
                    reputation for commerce and entertainment, while also
                    hinting at alternative, subterranean and often subversive
                    ways in which the city is viewed, used and occupied.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Roman Tours window, Grosvenor Museum, 27 Grosvenor St,
                        Chester, CH1 2DD
                      </strong>
                    </p>
                    <p><strong>Access Can be viewed 24/7 from street level.</strong></p>
                    <p>The window of <strong>22 Bridge Street Row
Chester, CH1 1NN</strong></p>
<p><strong>Access</strong> Bridge Street Row can be accessed from pavement level via a ramp on Pier Point Lane off
Bridge St West. Can be viewed 24/7.</p>
<p><strong>Old Market Forum car park,
Nicholas Street, Chester, CH1 2NX</strong></p>
                    <p>
                      <strong>Access</strong> can be viewed 24/7 from
                      street level
                    </p>                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>

                  </Typography>
                  <IconLink align="top" content="3" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>
                      Simeon Barclay (b.1975, Huddersfield; lives and works in
                      West Yorkshire, UK)
                    </strong>{' '}
                    draws on a diverse visual language, activating objects in
                    installations that with humorous undertones come to express
                    the paradoxes and ambiguities of situating and defining
                    ourselves within culture and tradition. In 2022, Barclay
                    received the Ares Art Award; in 2021, he was appointed to
                    the Arts Council Collection Acquisitions Committee, and
                    received an Art Fund Commission as part of BAS9. Barclay was
                    awarded the Paul Hamlyn Foundation Award for Visual Artists
                    and a Henry Moore Foundation award in 2020. He is currently
                    working on a commission for Deutsche Bank Art & Culture, and
                    this year his work will be included in the 12th edition of
                    Sculpture in the City. Simeon Barclay is represented by
                    Workplace, London.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Them Over Road is part of Chester Contemporary’s Core
                      Artist programme curated in conjunction with the Emerging
                      Artist programme by artistic director, Ryan Gander.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
