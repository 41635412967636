import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// import SorryPaul from '../../assets';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/dd1a7e9886749b6ac3f617dd76dbae5d.jpg"
          altText="alt text"
          credit="Image: © Nick Davies."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Nick Davies</h1>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <audio controls>
                  <source src="/SorryPaul2023.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    A Sweet Connection, Sorry Paul (Way After Goteddsday), 2023
                  </p>
                  <p>
                  Confronting the feelings left over from a successful act of self-defence during a football match in primary school, Nick Davies has created saturated cement forms to resemble jumpers for goalposts on Chester’s racecourse. In the middle ages, the racecourse, or Roodee – the world’s oldest – was the site of the violent Goteddsday (Shrove Tuesday) football matches, which were eventually banned in the 16th century.
                  </p>
                  <p>
                  Nick's sculptures reference the racecourse’s violent past as well as the nostalgia of his own misdemeanour.
                  </p>
                  <p>An accompanying sound recording that can be played on this page above is Nick’s recollection and apology for the fight that created this memory.</p>
                  <p>Thanks to Chester Racecourse for their support.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Gate 9, Chester Racecourse (open ground in middle of
                        Racecourse), New Crane St, Chester, CH1 4JD
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Open</strong> 24/7
                    </p>
                    <p>
                      <strong>Access</strong> Racecourse turf accessible from
                      street at track crossing point
                    </p>
                  </Typography>
                  <IconLink align="top" content="6" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    It Only Really Dies When It’s Forgotten, 2023
                  </p>
                  <p>
                    <em>The Phantom Public</em> by Walter Lippmann and{' '}
                    <em>The Public and its Problems</em> by John Dewey, two
                    books published in the 1920s, are the inspiration for this
                    multi-layered work which asks if such a thing as ‘the
                    public’ exists, and whether a successful, inclusive
                    democracy is possible.
                  </p>
                  <p>
                    The two books will be on display in the Library at
                    Storyhouse and will be acting in a way not unfamiliar to a
                    book, whilst at the same time being eerily unusual.
                  </p>
                  <p>
                    Once a week, Chester’s famous Town Crier will cross the city
                    centre silently, moving from one public bench to another
                    whilst contemplating. If you approach him he may respond by
                    offering some choice written words taken from the books.
                  </p>
                  <p>
                    A selection of phrases from each book will appear on public
                    noticeboards around Chester, the quotes becoming carriers
                    down the ages of a range of possible meanings that could
                    perhaps spark new, relevant conversations in public spaces
                    today.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      Books on display at{' '}
                      <strong>
                        Storyhouse, Hunter Street, Chester, CH12AR.
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Open</strong> Mon-Sat, 8am-11pm; Sun 9.30am-11pm
                    </p>
                    <p>
                      <strong>Access</strong> Hunter St entrance has ramp access
                      and automatic doors. The whole building is accessible via
                      lift, with wheelchair access to all areas. Storyhouse also
                      has a Changing Place and accessible toilet. More access
                      details here
                    </p>
                    <p>
                      <strong>
                        The Town Crier and noticeboard sites will vary
                      </strong>
                    </p>
                  </Typography>
                  <IconLink align="top" content="6" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Nick Davies (born in 1985, St. Asaph, UK)</strong>{' '}
                    lives in Prestatyn, North Wales. He often makes artworks to
                    try and make sense of the world with a belief that art can
                    help level the playing field, if only for a moment. Usually
                    driven by concepts, Nick’s output takes on differing forms
                    such as sculpting using Tipp-Ex and documenting whitewashed
                    empty shop windows across the UK. He mostly practices alone,
                    but also works with other artists and creatives on curated
                    and published work through Loophole Projects.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Nick’s work is part of Chester Contemporary’s Emerging
                      Artist programme curated in conjunction with the Core
                      Artist programme by artistic director, Ryan Gander; in
                      partnership with Storyhouse, Chester’s theatre, cinema and
                      library.
                    </em>
                  </p>
                  <p>
                    <em>
                      The programme offers the chance for five artists at a
                      pivotal point in their career to access mentoring and
                      professional development as well as an opportunity to
                      showcase their work within the Contemporary.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
