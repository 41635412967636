import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/print.jpeg"
          altText="Laura-Kate Draws print workshop"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>The GROW Art Lab: For the love of print!</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  Workshops with Laura-Kate Draws
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Help us celebrate the joy of growing fruit and vegetables inspired by the history of
food on the Rows and their architecture by joining artist Laura-Kate Draws at the
GROW Art Lab workshops. In this family friendly print making workshop, Laura-Kate
combines her love of gardening with being super-creative!
                  </p>
                  <p>
                  Laura-Kate will help you make your own relief print stamp inspired by fruit and
vegetables. Once your stamp is created you’ll learn how to make multiple

Prints, as well as having a go at screen printing.
                  </p>
                  <p>
                  The activity is ideal for those aged 6+. Younger participants are welcome too,
although they will need more support throughout the session from the adult
accompanying them.
                  </p>
                  <p>Places on the timed one-hour slots will need to be booked, and all young people will
need to be accompanied by an adult throughout the session. Both the young person
and accompanying adult will need to book a ticket.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 14 Oct</strong>
                    </p>
                    <p>
                      Session times
                      
<strong>10-11am<br />
11.15am-12.15pm<br />
12.45-1.45pm<br />
2-3pm</strong>
                      
                    </p>

                    <p>
                    Chester Visual Arts Gallery
(Former H&amp;M unit)
Grosvenor Shopping Centre
Eastgate St, Chester, CH1
                    </p>
                    <p>
                    <strong>Free</strong> – pre-booking essential for everyone attending, inc adults.
All materials will be provided.
                    </p>
                    <p>
                      <strong>Access</strong> Chester Visual Arts Gallery has level access from the shopping centre.
                    </p>
                  </Typography>
                  <Button
                    href={
                      'https://www.ticketsource.co.uk/chestercontemporary'
                    }
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                <p><strong>Laura-Kate Draws</strong> has been working as a freelance illustrator and workshop practitioner since 2009.
She has worked as an illustratior for a children’s book and numerous sculpture painting projects for
Wild in Art. Other clients have included The Royal Shakespeare Company, The New Birmingham
Library, Arts Council Collection and The Royal Society of Chemistry. She has also developed a range
of illustrated products. Much of Laura-Kate’s work focuses on her passion for the environment and her
fascination with animals, flora, fauna and the conservation of nature. Her practice over the last 10
years has had a strong focus on working with community groups in a variety of settings, and has
worked closely with National Museums Liverpool delivering workshops and creating learning
resources. Empowering creativity, sharing skills and encouraging the use of imagination and
experimentation is at the core of Laura-Kate’s creative practice.</p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
