import './index.css';

interface ButtonProps {
  href: string;
  className?: string;
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  href,
  className,
  ...props
}) => {
  return <a href={href} className={'element_button ' + className} {...props} />;
};
