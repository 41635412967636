import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
import { Hero } from '../components/Hero';

console.log(responsiveGrid, typography);

export function Walkstalksworkshops(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="section_grey">
        <Hero
          imgSrc="/content-images/6759fdc0fead4f79f072dd6e83640e2c.jpg"
          altText="alt text"
        />
      </div>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>Walks + talks + workshops</h1>
                <p>
                  A programme of walks, talks and workshops will accompany
                  Chester Contemporary from September. They’ll be updated
                  regularly, so check back for the latest news.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Hope is a group project – creative workshop"
                  blurb="with Donna Leishman"
                  image="/content-images/6759fdc0fead4f79f072dd6e83640e2c.jpg"
                  href="/HopeIsAGroupProject"
                  location="Grosvenor Park Terrace, Grosvenor Park"
                  date="Thurs 19 Oct, 2-5pm"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Impossible Stairs – creative workshop "
                  blurb="with Donna Leishman"
                  image="/content-images/walks/9f2f91dd71c22cdced4f189624ea3ad1.jpg"
                  href="/ImpossibleStairs"
                  location="The Wishing Steps, City Walls "
                  date="Fri 20 Oct, 2-5pm"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Chester Contemporary in the City – walk"
                  blurb="with James Dixon"
                  image="/content-images/walks/167f721eba9c84dbf1afd1a6a2da2698.jpg"
                  href="/ChesterContemporaryintheCity"
                  location="Exchange Square, Hunter St, Chester CH1 2AR"
                  date="Sun 24 Sept, 11am"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="The Archaeology of Chester Contemporary – talk"
                  blurb="with James Dixon"
                  image="/content-images/walks/03f5387dc0b8e2f94162b28a9ba23e72.jpg"
                  href="/TheArchaeologyofChesterContemporary"
                  location="Grosvenor Museum"
                  date="Sat 11 Nov, 2pm"
                />
              </div>
              
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Beneath the Surface, drawing from observation - talk"
                  blurb="with Julia Midgley"
                  image="/content-images/walks/busysite.jpeg"
                  href="/BeneaththeSurface"
                  location="Grosvenor Museum"
                  date="Sat 14 Oct, 3pm"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Hidden Gems of Chester Architecture - talk"
                  blurb="with Adrian Sumner"
                  image="/content-images/walks/130-Crop.jpeg"
                  href="/HiddenGemsofChester"
                  location="Grosvenor Museum"
                  date="Thurs 16 Nov, 3pm"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Gateway – creative walk and workshop"
                  blurb="with Wendy Connelly &amp; Robin Bailey"
                  image="/content-images/walks/Rectangle-11.png"
                  href="/Gateway"
                  location="Meet at St Werburgh Street"
                  date="Fri 27 Oct, 10am-1pm"
                />
              </div> 
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="The GROW Art Lab: For the love of print!"
                  blurb="with Laura-Kate Draws"
                  image="/content-images/walks/print.jpeg"
                  href="/Print"
                  location="Chester Visual Arts Gallery, Grosvenor Shopping Centre"
                  date="Sat 14 Oct, from 10am"
                />
              </div> 
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="The GROW Art Lab: Build and Make!"
                  blurb="with Laura-Kate Draws"
                  image="/content-images/walks/make.jpeg"
                  href="/Make"
                  location="Chester Visual Arts Gallery, Grosvenor Shopping Centre"
                  date="Thurs 26 Oct, 10am-12pm &amp; 1-3pm"
                />
              </div> 


              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="The History of Chester Racecourse and the Roodee - walk with Chris Clayton"
                  image="/content-images/walks/ezgif-5-34e249c4d3.jpg"
                  href="/chrisclayton"
                  location="Holiday Inn, New Crane Street"
                  date="Friday 13th Oct, 3pm"
                />
              </div> 
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Chester Roam with Modernist Moocher Steve Marland"
                  blurb="Roam around modern Chester with the Modernist Moocher Steve Marland"
                  image="/content-images/walks/chester3.jpg"
                  href="/Roam"
                  location="Storyhouse, Hunter Street"
                  date="Sat 7 Oct, 12pm"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-third l-03"
              >
                <ContentCard
                  title="Heritage Harvest"
                  blurb="Seasonal art &amp; food celebration with Squash"
                  image="/content-images/walks/IMG20230901125358.jpeg"
                  href="/HeritageHarvest"
                  location="St Mary’s Creative Space"
                  date="Sun 15 Oct, 11am - 1pm &amp; 2-4pm"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Walkstalksworkshops;
