import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/lucydent2.jpg"
          altText="alt text"
          credit="Image: © Lucy Dentith."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Lucy Dentith & JH Godwin Primary School</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Keeping You Warm and Dry
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    There are many different stories about the origins of
                    Chester’s famous Rows and why they were designed in their
                    distinctive galleried way, with one covered storey above
                    street level. One theory dating from the 18th century (and
                    mentioned by Robinson Crusoe author Daniel Defoe) is that
                    the covered walkways allowed for people to promenade or shop
                    whilst keeping out of the rain!
                  </p>
                  <p>
                    Artist Lucy Dentith has explored this story with Year 5
                    pupils at JH Godwin Primary and together they’ve created a
                    fabric wall hanging using appliqué and felt. Students
                    created their own fabric designs based on Chester’s
                    distinctive Tudor architecture by using collage techniques
                    and wet-felting designs. The umbrella quilt can be seen in
                    the display case outside Lowe & Sons on Bridge Street,
                    alongside objects that connect to the names of the Rows.
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils at JH Godwin Primary
                      School, Blacon, Chester.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      In the display case outside{' '}
                      <strong>
                        Lowe & Sons, 11 Bridge St Row East, Chester, CH1 1PD
                      </strong>
                    </p>
                    <p>
                      <strong>Free</strong> – no need to bookCan be seen 24/7
                    </p>
                    <p>
                      <strong>Access</strong> to Bridge Street Row East is
                      through the Grosvenor Shopping Centre. More Rows access
                      details here.
                    </p>
                  </Typography>
                  <IconLink align="top" content="5" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Lucy Dentith</strong> is an artist and art teacher
                    based in North Wales and Shropshire. She has been creating
                    sculptures, paintings, textiles, drawings and mixed-media
                    art for many years, all of which are showcased in her studio
                    space, Lulu’s Cabinet, in Holywell. She studied at Central
                    St Martins and London College of Fashion, completing a
                    degree in Technical Effects for the Performing Arts and went
                    on to create masks, puppets and props for film and theatre,
                    including Harry Potter and The Deathly Hallows. She has also
                    completed a Masters’ degree in Design Practice and has over
                    ten years’ experience teaching art in schools and running
                    workshops. Lucy’s artistic philosophy is rooted in the
                    acceptance of non-perfection and the beauty of mistakes,
                    encouraging those who work with her to do the same.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
