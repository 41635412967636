import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/davebix.jpg"
          altText="alt text"
          credit="Image: © Dave Bixter."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Dave Bixter & St Oswald's CE Primary School</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Mainsail
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Year 4 and 5 students from St Oswald’s CE Primary have
                    worked with artist Dave Bixter to create a patchwork
                    mainsail. The children used archive maps as the inspiration
                    for their wax pencil and ink drawings on fabric, and the
                    sail combines these individual drawings which explore the
                    different shorelines along the River Dee.
                  </p>
                  <p>
                    Each year group in St. Oswald’s is named after a bird. The
                    children who created Mainsail are Goldfinches. The red and
                    yellow colours found in the artwork represent the plumage of
                    a goldfinch, with the blue representing the sea.
                  </p>
                  <p>
                    Accompanying Mainsail is an original sea shanty written and
                    sung by the children and the artist. Scan the QR code on the
                    artwork at Deva Travel to listen to a performance of the sea
                    shanty!
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils of St Oswald’s CE Primary
                      School, Mollington, Chester.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      In the window of{' '}
                      <strong>
                        Deva Travel, 55 Bridge St Row East, Chester, CH1 1PP
                      </strong>
                    </p>
                    <p>
                      <strong>Free Can be seen 24/7</strong> – no need to book
                    </p>

                    <p>
                      <strong>Access</strong> Bridge St Row East is through the
                      Grosvenor Shopping Centre. More Rows access details here
                    </p>
                  </Typography>
                  <IconLink align="top" content="12" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Dave Bixter</strong> is a multidisciplinary artist
                    and musician based in Liverpool, who combines different art
                    forms and explores connections between drawing and
                    music-making. He uses music technology to create interactive
                    soundscapes and immersive multisensory experiences. Since
                    2005, Dave has delivered creative workshops and training for
                    schools, community groups and public events, using his
                    artwork to inspire others to explore their own creativity in
                    new and exciting ways. His work emphasises the importance of
                    creativity and of embracing an experimental approach that
                    welcomes mistakes and the unexpected as a positive part of
                    the creative process.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>{' '}
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="Sing Along Sea Shanty"
                  // blurb="The launch of Chester Contemporary in February with Councillor Louise Gittins, and Artist Director Ryan Gander in conversation with featured artist Simeon Barclay."
                  video={`<iframe width="560" height="315" src="https://www.youtube.com/embed/VHSG393E_Qc?si=BzBpVKQ_q3qhHp09" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-half">
                <ContentCard
                  title="Mainsail"
                  // blurb="Chester Contemporary artist Tim Foxon explains his No Look Portraits – watch him in action!"
                  video={`<iframe width="560" height="315" src="https://www.youtube.com/embed/y3HjzEP-MfQ?si=HPxCipii11taISlx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
