import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/9f2f91dd71c22cdced4f189624ea3ad1.jpg"
          altText="alt text"
          credit="Image ©  Donna Leishman"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Impossible Stairs</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Creative workshop with Donna Leishman
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Join artist Donna Leishman to help create a site-specific artwork at The Wishing Steps on Friday 20
October.
                  </p>
                  <p>
                    You’ll have the chance to transform a long flight of steps,
                    which date from c.1785, into a place of nocturnal wonder
                    using an everyday symbol of joy and success – the star
                    sticker! Create your own constellation, then, as the night
                    falls, our stars will start to glow for the enjoyment of
                    passers-by.
                  </p>
                  <p>
                    Impossible Stairs celebrates, and reminds people of, the
                    richness of Chester’s social heritage that often lies in
                    plain sight. It might perhaps even encourage a few new
                    wishes to be cast on the Wishing Steps!
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 20 Oct, 2-5pm</strong>
                    </p>
                    <p>
                      <strong>
                        The Wishing Steps, City Walls, Chester, CH1 1SB
                        (overlooking The Groves and Hickory's Smokehouse)
                      </strong>
                    </p>

                    <p>
                      <strong>Free.</strong> No need to book, just drop in.
                    </p>
                    <p>
                      Suitable for all ages, children under 16 to be accompanied
                      by an adult.
                    </p>
                    <p>
                      We’ll be outside so make sure you’re dressed for the
                      weather.
                    </p>
                    <p>All materials will be provided.</p>
                    <p>
                      <strong>Access</strong> The Wishing Steps are not
                      accessible
                    </p>
                  </Typography>
                  <IconLink align="top" content="2" href="/map" colour="green">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Donna Leishman‘s</strong> work is a combination of
                    critical writing, and digital and visual art with a
                    particular focus on the intersection of stories,
                    interactivity, and identity. Her work explores the reasons
                    why stories were created, and explores what new
                    possibilities they offer us culturally, by reframing
                    history, using folkloric traditions, and problematising
                    archetypes. Donna has had her work exhibited in museums,
                    galleries, conferences and festivals around the world.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
