import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/0f63df88c5ef32f764a61970b9e2e22e.png"
          altText="alt text"
          credit="Image: © Harry Grundy."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Harry Grundy</h1>
                  {/* <h2 className="typography_paragraph-8 typography_shadow">
                    HORSETAILS
                  </h2> */}
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">Janus Wept, 2023</p>
                  <p>
                    Janus was the two-headed Roman god of doors, gateways and
                    transitions. Now, his gaze is turned on Chester’s puddles -
                    unique forms that require rain to be seen. Centuries of
                    change have drawn a wonderful array of puddles on the city’s
                    patchwork streets: long puddle blades, starburst puddles,
                    and small nook puddles.
                  </p>
                  <p>
                    Artist Harry Grundy brings these forms to life with a series
                    of puddle different dishes modelled directly from puddles
                    found around Chester. They will be shown in the windows, and
                    sold from charity shops around the city.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      Puddle dishes can be found at charity shops on{' '}
                      <strong>Frodsham Street, Chester.</strong>
                    </p>
                    <p>
                      Each will be priced by the shop and proceeds will go to
                      the shop’s designated charity.
                    </p>
                  </Typography>
                  <IconLink align="top" content="9" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    The Mingling Tree, 2023
                  </p>
                  <p>
                    Sitting in a bespoke carriage of Grosvenor Park’s miniature
                    railway, a young tree rides the full circuit of the railway
                    as if it were a special passenger being introduced to the
                    rest of the park. A final home for the tree, with a view of
                    the railway, will be decided over the course of Chester
                    Contemporary, after which it will be planted permanently in
                    the park; the tree will know more about its surroundings
                    than any of its neighbours, bestowing an autonomy not often
                    found in nature.
                  </p>
                  <p>
                    <em>
                      With thanks to{' '}
                      <a href="https://gpmrchester.co.uk/" target="_blank">
                        Grosvenor Park’s Miniature Railway
                      </a>
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Grosvenor Park Miniature Railway, Grosvenor Park,
                        Chester, CH1 1QQ
                      </strong>
                    </p>
                    <p>
                      <strong>Railway opening hours</strong> Weds & Fri
                      11am-4pm; Sat & Sun 10am-5pm
                    </p>
                    <p>
                      <strong>Tickets to ride</strong> from £2 single
                    </p>
                    <p>
                      For more miniature railway information visit
                      < a href="https://gpmrchester.co.uk/">gpmrchester.co.uk</a>
                    </p>
                  </Typography>
                  <IconLink align="top" content="9" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Harry Grundy (b. 1993, London, UK)</strong> is a
                    Margate-based artist making art without disciplinary
                    boundaries. Curious, conceptual and performative, Harry
                    works in the natural world in a deeply unnatural time. With
                    a background in design, he treats his ideas with a rigorous
                    honesty and crafts them with a graphic eye. Fuelled by
                    disagreements with the long-dead Romantics, Harry asks the
                    viewer to look closely and critically at our relationship
                    with this phenomenal world.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Harry’s work is part of Chester Contemporary’s Emerging
                      Artist programme curated in conjunction with the Core
                      Artist programme by artistic director, Ryan Gander; in
                      partnership with Storyhouse, Chester’s theatre, cinema and
                      library.
                    </em>
                  </p>
                  <p>
                    <em>
                      The programme offers the chance for five artists at a
                      pivotal point in their career to access mentoring and
                      professional development as well as an opportunity to
                      showcase their work within the Contemporary.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
