import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Hero } from '../components/Hero';
import { Typography } from '../components/Typography';
import { IconLink } from '../components/IconLink';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Visit(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="section_grey">
        <Hero
          imgSrc="/content-images/ChesterClock.png"
          altText="alt text"
          credit="Image © Nessy-Pic"
        />
      </div>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>Visit</h1>
                <p className="typography_heading-2">
                  All you need to know for your visit to Chester Contemporary
                  this autumn. How to get here, how to get around, how to book
                  any tickets, and how you can access the Contemporary.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="feature_white">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-size="s-12 m-10"
              data-grid-start="m-2"
            >
              <Typography>
                <h2>About Chester</h2>
                <p>
                  Chester is a city where modern culture meets ancient history,
                  the north west of England's jewel in the crown. Layers of its
                  heritage can be seen in its architecture, archaeology and art.
                  Its people have a passion for creativity, with the city home
                  to events and celebrations all year round, as well as a
                  fantastic programme of live theatre, film and festivals. For
                  leisure and retail lovers, the city has a great selection of
                  cafés, bars and restaurants, with plenty of bijou independents
                  alongside the big names.
                </p>
                <p>
                  Chester is a compact and easily walkable city. There are
                  magical nooks, crannies, the beautiful River Dee, and of
                  course the city's unique Rows and city walls. Chester is ripe
                  for exploration, and with Chester Zoo, the north west's great
                  cities, and north Wales a stone's throw away, it's a great
                  base for further jaunts. For more visitor information check
                  out{' '}
                  <a
                    href="https://www.visitcheshire.com/chester"
                    target="_blank"
                  >
                    Visit Cheshire
                  </a>
                  .
                </p>
                <p>
                  Explore Chester Contemporary and see another side to this
                  fascinating city.
                </p>
                <h2>How to get here</h2>
                <h3>By train</h3>
                <p>
                  Chester is well-served by trains to and from destinations all
                  over the country.
                </p>
                <p>
                  Northern trains run services to Chester from towns and cities
                  all over the north, including from Manchester which is a
                  one-hour seven-minute journey.
                </p>
                <p>
                  The Merseyrail Network connects locations in Merseyside and
                  Lancashire with Liverpool City centre for onward connection to
                  Chester and Ellesmere Port.
                </p>
                <p>
                  Transport for Wales also run services from Manchester as well
                  as destinations in Wales including 16 trains a day to and from
                  Cardiff Central, and to Liverpool which is a 44-minute
                  journey.
                </p>
                <p>Avanti West Coast run trains to and from London Euston.</p>
                <p>
                  For full details,{' '}
                  <a
                    href="https://www.thetrainline.com/destinations/trains-to-chester"
                    target="_blank"
                  >
                    visit Trainline
                  </a>
                  .
                </p>
                <h3>Chester Park and Ride</h3>
                <p>
                  Park and Ride is cheap and easy to access from local roads and
                  the motorway network. Tickets are £2 per adult. Up to three
                  children can travel for free with each fare paying adult.
                  Buses operate three separate circular routes direct into the
                  city, then back to your car park.{' '}
                  <a
                    href="https://www.cheshirewestandchester.gov.uk/residents/transport-and-roads/public-transport/buses/park-and-ride"
                    target="_blank"
                  >
                    Full details about the Park and Ride service can be found
                    here
                  </a>
                  .
                </p>
                <h3>By bike</h3>
                <p>
                  Chester's cycle routes can be found using the{' '}
                  <a
                    href="https://itravelsmart.co.uk/itravelsmart-app/"
                    target="_blank"
                  >
                    itravelsmart app
                  </a>{' '}
                  which can be downloaded from the{' '}
                  <a
                    href="https://itravelsmart.co.uk/itravelsmart-app/"
                    target="_blank"
                  >
                    itravelsmart website
                  </a>
                  . Maps for cycle routes around the city can be{' '}
                  <a
                    href="https://www.chestercyclecity.org/route-map-library/"
                    target="_blank"
                  >
                    downloaded here
                  </a>
                  .
                </p>
                <p>
                  There's a cycle hub in New Market car park on Hunter Street
                  which is open from 6.30am to 11.30pm (an access card can be
                  bought from 2pm each day from the car park office).
                </p>
                <p>
                  On-street cycle parking is available across the city,
                  including at Exchange Square, Princess Street, Hunter Street,
                  Bridge St/Grosvenor St and outside the Cathedral.
                </p>
                <h3>By bus</h3>
                <p>
                  Chester is well served by bus services travelling around and
                  through the city. You'll find timetable and{' '}
                  <a
                    href="https://www.cheshirewestandchester.gov.uk/residents/transport-and-roads/public-transport/bus-timetables-route-maps/bus-service-timetables"
                    target="_blank"
                  >
                    journey planning details here
                  </a>
                  . Most single journeys are just £2. Toilet facilities,
                  changing places and amenities are located at Chester Bus
                  interchange.
                </p>
                <h3>Scooter hire</h3>
                <p>
                  Scooters are available to hire in Chester via the Ginger
                  Shared Transport app. Prices start from £2.20 for 20 mins.
                  Check out the{' '}
                  <a href="https://www.ginger.town/chester" target="_blank">
                    Ginger website
                  </a>{' '}
                  for more details.
                </p>
                <h3>By car</h3>
                <p>
                  Main motorway links to Chester and Cheshire are through the
                  M6, M56 and M53. Park and ride services are signposted from
                  the network. You can find{' '}
                  <a
                    href="https://www.cheshirewestandchester.gov.uk/residents/transport-and-roads"
                    target="_blank"
                  >
                    detailed parking information here
                  </a>
                  .
                </p>
                <h2>Visitor information</h2>
                <p>
                  The friendly staff at Chester Visitor Information are on hand
                  to help you if you have any questions about your visit. You
                  can find them at the Town Hall, Northgate St, Chester CH1 2HJ
                  or call 01244 405340.{' '}
                </p>
                <h2>Access</h2>
                <p>
                  Chester is the first British city to win the coveted European
                  Access City Award for its commitment to ensuring that it
                  continues to become a more accessible city. Wheelchair
                  accessibility to shops in Chester is good and it's possible to
                  gain step-free access to the Rows at various points.
                </p>
                <p>
                  <a
                    href="https://www.accessable.co.uk/cheshire-west-and-chester-council"
                    target="_blank"
                  >
                    The AccessAble guide to Chester and Cheshire West
                  </a>{' '}
                  covers access details for a selection of hotels, bars,
                  restaurants and attractions in Chester. You can also{' '}
                  <a
                    href="https://www.visitcheshire.com/dbimgs/Accessible%20Chester.pdf"
                    target="_blank"
                  >
                    download a copy of Chester city centre access: A Guide for
                    Disabled People
                  </a>{' '}
                  which includes more details and maps for accessible parking,
                  access to the Rows and public toilets in Chester.
                </p>
                <h3>Blue Badge holders</h3>
                <p>
                  There are two parking areas dedicated to Blue Badge holders in
                  Frodsham Street and Hamilton Place (four hour maximum stay).
                  In addition, Blue Badge holders can park free of charge for up
                  to four hours on any City Council pay and display car park,
                  with the exception of Trinity Street. If you want to stay
                  longer than four hours, please buy a pay and display ticket
                  for the additional time, and display the ticket next to your
                  badge and clock. Please note that the pay & display machine in
                  Kaleyards car park only starts accepting cash after 6pm, and
                  there's no pay & display machine in Hamilton Place.
                </p>
                <h3>Shopmobility</h3>
                <p>
                  <a
                    href="https://dialwestcheshire.org.uk/services/shopmobility/"
                    target="_blank"
                  >
                    Shopmobility
                  </a>{' '}
                  West Cheshire provides daily use of mobility scooters, powered
                  wheelchairs and manual wheelchairs. Advance booking is
                  recommended.
                </p>
                <h2>Booking Information</h2>
                <p>
                  Chester Contemporary artworks are free to visit, with no need
                  to book tickets. Venues will have their own opening times,
                  which you can find on the individual event pages on this
                  website.
                </p>
                <p>
                  Some walks, talks and workshops may charge an admission fee.
                  Tickets for these can be booked via the link on the event
                  page.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Visit;
