import './index.css';

interface HeroProps {
  imgSrc: string;
  altText: string;
  credit?: string;
}

export const Hero: React.FC<HeroProps> = ({ imgSrc, altText, credit }) => {
  return (
    <div className="element_hero-image">
      <img src={imgSrc} alt={altText} />
      {credit && <span className="element_hero-image-credit">{credit}</span>}
    </div>
  );
};
