import React, { useEffect } from 'react';

import { Carousel } from '../components/Carousel';
import { ContentCard } from '../components/ContentCard';
import { Typography } from '../components/Typography';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
import { Hero } from '../components/Hero';

console.log(responsiveGrid, typography);

export function Schools(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="section_grey">
        <Hero
          imgSrc="/content-images/2fca60a759745e499dfdc000bcfbeec1.jpg"
          altText="alt text"
        />
      </div>
      <div className="feature_grey">
        <div className="element_grid-container">
          <div className="element_grid-row">
            <div
              className="element_grid-item"
              data-grid-start="m-2"
              data-grid-size="s-12 m-10 l-8"
            >
              <Typography>
                <h1>Schools’ programme</h1>
                <p>
                  Discover work created by Chester primary schools working with
                  artists, inspired by the city and the Contemporary programme.
                  All work is free to visit and located across the city centre.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_grey">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Dave Bixter & St Oswald’s CE Primary"
                  blurb="Mainsail"
                  image="/content-images/schools/davebix.jpg"
                  href="/StOswaldsCEPrimarySchool"
                  location="Deva Travel, Bridge St Row"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Lucy Dentith & Belgrave Primary"
                  blurb="Deva-Dee-Diver"
                  image="/content-images/schools/lucydent.jpg"
                  href="/BelgravePrimarySchool"
                  location="Pink Lettuce, Bridge St Row"
                  date="23 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Lucy Dentith & JH Godwin Primary"
                  blurb="Keeping You Warm and Dry"
                  image="/content-images/schools/lucydent2.jpg"
                  href="/JHGodwinPrimarySchool"
                  location="Lowe & Sons, Bridge St Row"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Sarah Gallagher-Hayes & Guilden Sutton C of E Primary"
                  blurb="Willow Horse"
                  image="/content-images/schools/sarahgall.jpg"
                  href="/GuildenSuttonCofEPrimarySchool"
                  location="St Mary's Creative Space"
                  date="22 Sept - 1 Dec"
                />
              </div>
              {/* row */}
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Olivia Glasser & Capenhurst CE Primary"
                  blurb="Bumpee Smoov Bumpee"
                  image="/content-images/schools/oliviaglas.jpg"
                  href="/CapenhurstCEPrimarySchool"
                  location="Rainbow Tea Rooms, Bridge Street"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Rachel Green & Cherry Grove Primary"
                  blurb="Merchant Vessels"
                  image="/content-images/schools/rachelgreen.jpg"
                  href="/CherryGrovePrimarySchool"
                  location="Various sites"
                  date="22 Sept - 1 Dec"
                />
              </div>
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Neuza Morais & Dee Point Primary / The Grosvenor Park CofE Academy "
                  blurb="The Missing Story"
                  image="/content-images/schools/neuza.jpg"
                  href="/GrosvenorParkAcademyDeePointPrimarySchool"
                  location="Chester Visual Arts Gallery, Grosvenor Shopping Centre"
                  date="22 Sept - 1 Dec"
                />
              </div>

              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Clare Owens & St Werburgh’s and St Columba’s Catholic Primary"
                  blurb="The City Unfolds"
                  image="/content-images/2fca60a759745e499dfdc000bcfbeec1.jpg"
                  href="/StColumbasCatholicPrimarySchool"
                  location="Pictura, Eastgate Row North"
                  date="23 Sept - 1 Dec"
                />
              </div>
              {/* row */}
              <div
                className="element_grid-item"
                data-grid-size="s-half m-third l-03"
              >
                <ContentCard
                  title="Claire Weetman & Mickle Trafford Village School"
                  blurb="The City Unfolds"
                  image="/content-images/schools/weetman.jpg"
                  href="/MickleTraffordVillageSchool"
                  location="Waterstone’s, Eastgate Row South"
                  date="22 Sept - 1 Dec"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Schools;
