import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/ezgif-5-34e249c4d3.jpg"
          altText="alt text"
          credit="Image © Chris Clayton"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>The History of Chester Racecourse and the Roodee - walk with Chris Clayton</h1>
                  {/* <h2 className="typography_paragraph-8 typography_shadow">
                  Workshops with Laura-Kate Draws
                  </h2> */}
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Racing in Chester started in 1539, making this the oldest operating racecourse in the world. But the site is also full of ancient history from its origins as the Roman harbour, through to medieval happenings and the early version of football played on its silted and reclaimed grass.  This annual match was banned in 1533 for being too dangerous!
 </p><p>
 The short walking tour of about 60 minutes, led by the racecourse surveyor Chris Clayton, starts at the racecourse hotel, the Holiday Inn Express on New Crane Street (parking available), then meanders through the Grandstands and along Chester’s City Walls to finish overlooking the art installation “A Sweet Connection, Sorry Paul (Way After Goteddsday), 2023 by Nick Davies.  The tour will tell the history and stories which make the racecourse such a special place, and a perfect location for this artwork.
 </p>               </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sun 24th September 2023</strong>
                    </p>
                    <p>
                      11:30AM - 12:30PM
                    </p>
                    <p>
                      Holiday Inn Express Chester - Racecourse, Chester, CH1 2LY
                    </p>
                    <p>
                    <strong>Free</strong>
                    </p>
                    <p>
                      <strong>Access</strong> The tour involves steep steps so is not suitable for wheelchair users or those with mobility issues.
                    </p>
                  </Typography>
                  <Button className='spacing_bottom-medium'
                    href={'https://www.ticketsource.co.uk/chestercontemporary/racecourse-walk-with-chris-clayton/e-ebrlga'}
                  >
                    Book Here
                  </Button>
                </EventContainer>
                <EventContainer>
                  <Typography>
                    <p>
                      Fri 13 Oct, 3pm
                    </p>
                    <p>
                      Holiday Inn Express Chester - Racecourse, Chester, CH1 2LY
                    </p>
                    <p>
                    <strong>Free</strong>
                    </p>
                    <p>
                      <strong>Access</strong> The tour involves steep steps so is not suitable for wheelchair users or those with mobility issues.
                    </p>
                  </Typography>
                  <Button className='spacing_bottom-medium'
                    href={'https://www.ticketsource.co.uk/chestercontemporary/racecourse-walk-with-chris-clayton/e-ebrlra'}
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            {/* <LineBreak /> */}

            {/* <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Donna Leishman‘s</strong> work is a combination of
                    critical writing, and digital and visual art with a
                    particular focus on the intersection of stories,
                    interactivity, and identity. Her work explores the reasons
                    why stories were created, and explores what new
                    possibilities they offer us culturally, by reframing
                    history, using folkloric traditions, and problematising
                    archetypes. Donna has had her work exhibited in museums,
                    galleries, conferences and festivals around the world.
                  </p>
                  <p> TODO: social link</p>
                  <br />
                  <br />
                </Typography>
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
