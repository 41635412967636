import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/schools/lucydent.jpg"
          altText="alt text"
          credit="Image: © Lucy Dentith."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Lucy Dentith & Belgrave Primary School</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Deva-Dee-Diver
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    A Sea Lady emerges from the waters around Chester. She’s
                    been on her travels and on her journey back has collected
                    lots of seaweed – and plastic. She’s weighed down with
                    plastic and a heavy heart – both literally and
                    metaphorically.
                  </p>
                  <p>
                    Inspired by Chester’s history as an important medieval port,
                    artist Lucy Dentith and the Year 5 pupils of Belgrave
                    Primary School have used recycled fabric and plastic
                    bottles, and have made felt seaweed, to create an
                    installation which reflects on the quantity of plastic in
                    our oceans, and encourages us all to continue to recycle,
                    restore and reuse.
                  </p>
                  <p>
                    <em>
                      Thanks to the staff and pupils of Belgrave Primary School,
                      Chester.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Cavani, 16, Eastgate Row South, Chester, CH1 1LF
                      </strong>
                    </p>
                    <p>
                      <strong>Access</strong> There is level access to Eastgate
                      Row South through the Grosvenor Shopping Centre. More Rows
                      access details here.
                    </p>
                    <p>
                      <strong>
                        Julie Colclough Gallery, 59 Watergate Row South,
                        Watergate St, Chester, CH1 2LE
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                  </Typography>
                  <IconLink align="top" content="1" href="/map" colour="blue">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Lucy Dentith</strong> is an artist and art teacher
                    based in North Wales and Shropshire. She has been creating
                    sculptures, paintings, textiles, drawings and mixed-media
                    art for many years, all of which are showcased in her studio
                    space, Lulu’s Cabinet, in Holywell. She studied at Central
                    St Martins and London College of Fashion, completing a
                    degree in Technical Effects for the Performing Arts and went
                    on to create masks, puppets and props for film and theatre,
                    including Harry Potter and The Deathly Hallows. She has also
                    completed a Masters’ degree in Design Practice and has over
                    ten years’ experience teaching art in schools and running
                    workshops. Lucy’s artistic philosophy is rooted in the
                    acceptance of non-perfection and the beauty of mistakes,
                    encouraging those who work with her to do the same.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Chester Contemporary’s schools’ programme is delivered in
                      partnership with Edsential. The programme has been
                      inspired by the themes: A Stitch in Time, To the Sea To
                      the Sea, Food Glorious Food and Row Stories, and is part
                      of the cultural programme of the Chester High Street
                      Heritage Action Zone (the Rows), funded by Historic
                      England and supported by Cheshire West and Chester
                      Council.
                    </em>
                  </p>
                  <p>
                    <em>
                      Edsential is a Community Interest Company providing
                      co-created, progressive, quality creative and cultural
                      experiences for schools, families and communities,
                      enabling children and young people to communicate what
                      they see, feel, and think, as well as develop new talents,
                      confidence and aspiration.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
