export const setupComponent = (component: any, styles:string) => {
    const shadow:ShadowRoot = component.attachShadow({mode: 'open'})
      
    const componentStyleSheet = new CSSStyleSheet();
    componentStyleSheet.replaceSync(styles);

    shadow.adoptedStyleSheets = [componentStyleSheet];

    // add a slot TODO: neaten this up
    if (shadow.innerHTML === '') {
      shadow.innerHTML += `<slot></slot>`; // add an unnamed slot to get random html from
    }

    console.log(component.props)
}