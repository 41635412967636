import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/130-Crop.jpeg"
          altText="alt text"
          credit='Image © Julia Midgley'
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Hidden Gems of Chester Architecture</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  Talk with Adrian Sumner
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Using the Grosvenor Museum's Topographical collection as inspiration, and spotlighting the series of works commissioned from Contemporary Chester artists featured in Peter Boughton's celebrated book Picturesque Chester, this talk explores the wide diversity of styles which have been used to describe and define both the modern life and the historical significance of 'Deva Cestris'.
</p><p>
Adrian will look at Roman Chester, at the ever-popular watercolours of Louise Rayner (not a Cestrian herself!), and the prints of George Cuitt, as well as images by the unrivalled JMW Turner and Charles Ginner. He will also consider the great 'classical' landscapes of the River Dee by Welshman Richard Wilson, modern railway posters and the part the noted illustrator Randolph Caldecott had to play in Chester’s visual history. Also featuring the Old Market Hall, the Chester Odeon, the Amphitheatre, the buildings of John Douglas, and, of course, Thomas Harrison's wonderful bridge.

                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Thurs 16 Nov, 3pm</strong>
                    </p>
                    <p>
                      <strong>
                        Grosvenor Museum, 27 Grosvenor Street, Chester, CH1 2DD
                      </strong>
                    </p>
                    <p>
                      <strong>£2</strong>
                    </p>
                    <p>
                      <strong>Access</strong> The Lecture Theatre is on the
                      ground floor of the Museum and is accessible via lift from
                      street level. Other floors of the Museum have no lift
                      access.
                    </p>
                  </Typography>
                  <Button
                    href={'https://www.ticketsource.co.uk/chestercontemporary/hidden-gems-of-chester-architecture-talk-by-adrian-sumner/e-dgmejo'}
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Adrian Sumner</strong>, a graduate of the Liverpool College of Art, has worked for 35 years as a painter, an illustrator, lecturer for various academic and public institutions and arts development officer for Chester City Council and Cheshire West and Chester Council. He was the organiser of the Chester Baudelaire Festival and originator of the Grosvenor Museum Open Exhibition. Today, he divides his time between developing and leading study tours, lecturing to a wide range of audiences in Britain and across Europe, organizing exhibitions, and painting and exhibiting his own work. He is a member of the Manchester Academy of Fine Art, of the Liverpool Academy of Art and was a Registered Lecturer for NADFAS (the National Association of Decorative and Fine Arts Societies (now The Arts Society).
                  </p>
                  {/* <p> TODO: social link</p> */}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
