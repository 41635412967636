import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
import { Hero } from '../../components/Hero';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);

  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/a341e7d6339dc781d955a255be969023.jpg"
          altText="alt text"
          credit="Image: © Charlotte van Berckel."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Charlotte van Berckel</h1>
                </Typography>
              </div>
            </div>

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Chester from Boughton, 1879 by John Finnie, reimagined, 2023
                  </h2>
                  <p>
                    The Grosvenor Museum is home to a fine collection of
                    sculpture and paintings amongst which sits this work{' '}
                    <em>
                      Chester from Boughton painted by John Finnie in 1879
                    </em>
                    . For the Contemporary, van Berckel asks us to look at this
                    familiar landscape of Chester in a new light by turning it
                    upside down, encouraging us to experience what we think we
                    know with fresh eyes. Disrupting the formal language of the
                    collection allows us to see the painting, the collection as
                    a whole, and Chester itself, as if for the first time.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        The Art Gallery, Grosvenor Museum, 27 Grosvenor Street,
                        Chester, CH1 2DD
                      </strong>
                    </p>
                    <p><strong>FREE</strong> - no need to book</p>

                    <p>
                      <strong>Open</strong> Open Tues-Sat 10.30am-5pm (last
                      admission 4.15pm) Sunday 1-4pm (last admission 3.15pm).
                      Closed Mondays.
                    </p>
                    <p>
                      <strong>Access</strong> Access the work is located
                      upstairs at the Museum.
                    </p>
                  </Typography>
                  <IconLink align="top" content="5" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    My Finest Swing, 2023
                  </h2>
                  <p>
                    A tennis ball perches casually on top of an oil painting in
                    the lobby of one of the city’s grandest hotels. How does
                    this unexpected context of the outside indoors, play with
                    our perceptions of space and our understanding of where art
                    should sit? Is this naughty, transgressive, or absolutely
                    normal for a city like Chester where the modern rubs
                    shoulders with the ancient?
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        The Chester Grosvenor, Eastgate, Chester, CH1 1LT
                      </strong>
                    </p>
                    <p><strong>FREE</strong> - no need to book</p>

                    <p>
                      <strong>Hotel lobby open</strong> 24/7
                    </p>
                    <p>
                      <strong>Access</strong> The lobby of The Chester Grosvenor
                      is accessible via a portable ramp at the hotel's front
                      steps.
                    </p>
                  </Typography>
                  <IconLink align="top" content="5" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    The Visitors’ Book, 2023
                  </h2>
                  <p>
                    A leather-bound visitors’ book is laid out open for all who visit to write something on its pages. How
                    will you respond? What moments will the book capture? What stories will it tell at the end of ten
                    weeks and how will these stories be passed on? The Visitors’ Book is a new kind of social media for
                    Chester Contemporary, a living portrait of daily life in the city.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>Chester Visitor Information Centre,
                      Town Hall, Northgate Street,
                      Chester, CH1 2HJ</strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>

                    <p>
                      <strong>Open</strong> Mon-Sat 9am-5pm; Sun 10am-4pm
                    </p>
                    <p>
                      <strong>Access</strong> Chester Visitor Information Centre is accessible via ramp from street level
                    </p>
                  </Typography>
                  <IconLink align="top" content="5" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  Making Merry
                  </h2>
                  <p>
                    Using the local cafes toilets as a canvas, this ephemeral, playful work celebrates the people
                    and passion of Chester and elevates their sound to the spectacular allowing us to see and
                    experience both the space and the voices anew. It is a picture of modern life that we can
                    stand in and become part.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Bridge Cafe and Bistro,
                      39 Bridge Street,
                      Chester,
                      CH1 1NG</strong>
                    </p>
                    <p>
                      <strong>Open</strong> 9am - 4pm, 7 days a week.
                    </p>
                  </Typography>
                  <IconLink align="top" content="5" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Charlotte van Berckel</strong> completed her degree
                    in Fine Art from City and Guilds of London Art School in
                    2022. She makes postmodern sculptures which are like
                    canvases of modern life taking the form of tangible objects
                    and ephemeral experiences. She is interested in making work
                    that captures the feeling of the fleeting moment and
                    elevating the everyday to the realm of the spectacular by
                    taking over the environment using gesture, intervention,
                    space and context. Her work is playful and disruptive and is
                    intended to bring joy to all who encounter it. Charlotte lives and works in London.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Charlotte’s work is part of Chester Contemporary’s
                      Emerging Artist programme curated in conjunction with the
                      Core Artist programme by artistic director, Ryan Gander;
                      in partnership with Storyhouse, Chester’s theatre, cinema
                      and library.
                    </em>
                  </p>
                  <p>
                    <em>
                      The programme offers the chance for five artists at a
                      pivotal point in their career to access mentoring and
                      professional development as well as an opportunity to
                      showcase their work within the Contemporary.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
