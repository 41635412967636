import './Carousel.css';

export const Carousel = ({ ...props }) => (
  <div className="component_carousel" {...props}>
    <div className="component_carousel__item-container">
      <div className="component_carousel__item">
        <img
          src="/content-images/081fcbcbc0e16d398a1353cbdc06553a.jpg"
          alt=""
        />
      </div>
      <div className="component_carousel__item">
        <img
          src="/content-images/38c54f387ee2722b2a15c527d852cbfb.jpg"
          alt=""
        />
      </div>
      <div className="component_carousel__item">
        <img
          src="/content-images/0aeff509aaf019044f9bbab3c3d302ae.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
);
