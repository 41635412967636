import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/68355207d00cd96cf08c3ee473a4f651.jpg"
          altText="alt text"
          credit="Image: © William Lang."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>William Lang</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Crop, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Chester Contemporary emerging artist William Lang’s performance and installation Crop uses
Chester Cathedral’s cloisters as a blueprint for a stage mown into the lawn on The Dean’s Field. The
space is activated by a durational performance that moves the viewer through the circadian rhythm of
the day. A sculptural intervention appears in the form of bespoke metal wind chimes acting as strange
talismanic love letters to the city’s waste and industrialisation.
                  </p>
                  <p>
                  The performance is best viewed from the city walls that semicircle the giant lawn.
                  </p>
                  <p>
                    A sculptural intervention sits alongside the performance in
                    the form of small metal bespoke wind chimes hung near and
                    above places of waste disposal. The light ethereal chimes
                    play in contrast to the heavy permanence of the waste, while
                    serving to acknowledge the part we’ve played in creating the
                    rubbish, and evoking the stories it holds.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      Performances:<br /><strong>
                      
Sat 23 &amp; Sun 24 Sept 3pm (45 Mins)<br />
25 Sept -2nd Oct 11am, 4pm (20-25 mins)
                      </strong>
                    </p>
                    <p>
                      The Dean’s Field is located in the grounds of{' '}
                      <strong>
                        Chester Cathedral, St Werburgh’s St, Chester, CH1 2DY
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Access</strong> There is a ramp up to the walls
                      through Kaleyards Gate. There is one step down from the
                      pavement to the cobbled street, and then onto a flat
                      pathway which leads directly to The Dean’s Field which is
                      accessible for wheelchair users.
                    </p>
                  </Typography>
                  <IconLink align="top" content="10" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>Windchime locations TBC</strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>Can be viewed 24/7 from street level</p>
                  </Typography>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>William Lang (b. 1995)</strong> is a visual artist
                    and performance maker who works with the moving body.
                    William’s practice utilizes improvisation as a generator of
                    embodied aesthetics; drawing on qualities of horror,
                    queerness, clowning and contemporary dance. William’s
                    performances often engage conversations about a contrasting
                    relationship between the moving body and sculpture.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      William’s work is part of Chester Contemporary’s Emerging
                      Artist programme curated in conjunction with the Core
                      Artist programme by artistic director, Ryan Gander; in
                      partnership with Storyhouse, Chester’s theatre, cinema and
                      library.
                    </em>
                  </p>
                  <p>
                    <em>
                      The programme offers the chance for five artists at a
                      pivotal point in their career to access mentoring and
                      professional development as well as an opportunity to
                      showcase their work within the Contemporary.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
