import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/johnak/AKOM100001_12.jpg"
          altText="alt text"
          credit="Image: Mnemosyne, 2010. © Smoking Dogs Films; Courtesy Smoking Dogs Films and Lisson Gallery."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>John Akomfrah</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Mnemosyne, 2010
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    A haunting exploration of memory, myth and migration
                  </p>
                  <p>
                    Mnemosyne was the mother of the Nine Muses, and the
                    personification of memory in Greek mythology. Akomfrah’s
                    tone poem is split into verses named after her daughters:
                    Tragedy, History, Music, Sacred Song, Astronomy, Comedy,
                    Erotic Love and Dance.
                  </p>
                  <p>
                    The piece uses a vast array of archival material to
                    hauntingly recast and retell the experiences of post-war
                    immigrants – resuscitating the now familiar images of our
                    multicultural and diasporic histories.
                  </p>
                  <p>
                    Centering on the West Midlands from 1960 to 1981 and
                    interlaced with images of an unidentified frozen wasteland,
                    contemporary ‘portraits’ of Birmingham, narrative and
                    literary voices and a dub infused soundtrack,{' '}
                    <em>Mnemosyne</em> is an evocative bricolage which questions
                    memory and suggests the possibility for endless
                    re-interpretation of historical events.
                  </p>
                  <p>
                    For Chester Contemporary, <em>Mnemosyne</em> evokes the
                    spirit of the city’s once-thriving port, which existed on
                    the spot where the racecourse now stands.
                  </p>
                  <br />
                  <p>Single channel HD colour video, 5.1 sound</p>
                  <p>45 minutes 6 seconds</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sun 24 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        Gallery One, Grosvenor Museum, 27 Grosvenor Street,
                        Chester, CH1 2DD
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Open</strong> Tues-Sat 10.30am-5pm (last admission
                      4.15pm); Sunday 1-4pm (last admission 3.15pm). Closed
                      Mondays.
                    </p>
                    <p>
                      <strong>Access</strong> Gallery One is on the ground floor
                      of the Museum and is accessible via lift from street
                      level. Other floors of the Museum have no lift access.
                    </p>
                  </Typography>
                  <IconLink align="top" content="1" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>
                      John Akomfrah CBE RA (born 1957; lives and works in
                      London, UK)
                    </strong>{' '}
                    is an artist and filmmaker whose films and multi-screen
                    video installations explore major issues including racial
                    injustice, colonial legacies, diasporic identities,
                    migration and climate change. Akomfrah was a founding
                    member, with David Lawson and Lina Gopaul, of the hugely
                    influential Black Audio Film Collective, whose first film
                    Handsworth Songs (1986) explored the events around the 1985
                    riots in Birmingham and London. His multichannel video works
                    are ambitious, multi-screen installations which are shown in
                    galleries and museums worldwide. In 2017, Akomfrah won the
                    Artes Mundi prize, the UK’s biggest award for international
                    art. He was honoured with a knighthood in the King’s New
                    Year’s Honours list, 2023, and will represent the UK at the
                    Venice Biennale in 2024.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Mnemosyne is part of Chester Contemporary’s Core Artist
                      programme curated in conjunction with the Emerging Artist
                      programme by artistic director, Ryan Gander.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
