import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/IMG20230901125358.jpeg"
          altText="alt text"
          // credit="Image ©  Donna Leishman"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Heritage Harvest</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  Seasonal art &amp; food celebration with Squash
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Celebrate harvest time by joining in seasonal food and art making
inspired by the rich heritage of Chester and the Rows. Join the
Squash crew of artists, cooks &amp; gardeners to:
                  </p>
<ul>
  <li>Make butter &amp; pancakes</li>
  <li>Press heritage apples &amp; pears to make fresh juice</li>
  <li>Draw the harvest in the pop-up seasonal studio</li>
  <li>Add your own autumn produce to the harvest altar</li>
</ul>
<p>Free drop-in event. Everyone welcome.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sun 15 Oct, 11am - 1pm &amp; 2-4pm</strong>
                    </p>
                    <p>
                    St Mary’s Creative Space, St Mary&#39;s Hill, CH1 2DW
                    </p>
                    <p>
                      <strong>FREE</strong>
                    </p>
                    <Button
                    href={
                      'https://www.ticketsource.co.uk/chestercontemporary'
                    }
                  >
                    Book Here
                  </Button>
                  </Typography>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Squash</strong> is a bold and ambitious, award-winning community
organisation, rooted and home-grown on Windsor Street, Liverpool
8 since 2010. Embracing an arts, food and environmental focus, we
are a diverse group of local people with a broad range of skills,
committed to effecting creative, participatory, positive social change
in our neighbourhood.</p><p>
Ours is a 100-year vision, inspired by nature; we are actively
transforming our Windsor Street Neighbourhood into a people-
powered place that is known throughout the world for being a
playful, resilient and loving community where everybody thrives!
Find out more at <a href="squashliverpool.co.uk">squashliverpool.co.uk</a> / @squashliverpool
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
