import { setupComponent } from '../componentSetup';

const styles = `:host { display:block; background: #333; color: #fff; } * {color:green;}`;

const changeHandler = (change: object) => {
  console.log(change, 'changee!!!!');
};

class Component extends HTMLElement {
  static get observedAttributes() {
    return ['cheese'];
  }
  static get props() {
    // return observedProps
    return ['json']; // should i make this so the name of the handler for the prop change is defined too? e.g [{"propName":"json", "handler":"jsonHandler"}]
  }
  constructor() {
    super();
    setupComponent(this, styles);
  }
  connectedCallback() {
    this.innerHTML = 'Im a button';
    this.setAttribute('cheese', 'yes very');
    console.log(Component.props, '<- props');
    this.addEventListener('click', (e) => {
      e.stopPropagation();

      console.log('dont touch me', this.tabIndex);
    });
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    changeHandler({ name, newValue, oldValue });
    // console.log(`name:${name} old:${oldValue} new:${newValue}`);
    // this.shadowRoot?.innerHTML = `name:${name} old:${oldValue} new:${newValue}`;
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'wc-button': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
customElements.define(`wc-button`, Component);

export const Button = {};
