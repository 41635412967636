import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/167f721eba9c84dbf1afd1a6a2da2698.jpg"
          altText="alt text"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Chester Contemporary in the City</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Walk with James Dixon
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    A walking tour with conservation officer and archaeologist
                    Dr James Dixon. The tour will take in the sites and spaces
                    that the Contemporary is inhabiting for its ten weeks in the
                    city, with James exploring how Chester Contemporary speaks
                    to modern Chester – and exactly what it’s saying.
                  </p>
                  <p>
                    The tour will visit sites of artworks, as well as other
                    nearby locations that help reveal the creative city of
                    today.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sun 24 Sept, 11am</strong>
                    </p>
                    <p>
                      Meet at{' '}
                      <strong>
                        Exchange Square, Hunter St, Chester CH1 2AR
                      </strong>
                    </p>
                    <p>
                      <strong>Access</strong> There is level access to Eastgate
                      Row South through the Grosvenor Shopping Centre. More Rows
                      access details here.
                    </p>

                    <p>
                      <strong>FREE</strong> - ticket required
                    </p>
                  </Typography>
                  <Button
                    href={
                      'https://www.ticketsource.co.uk/chestercontemporary/chester-contemporary-in-the-city-walk/e-vmqplo'
                    }
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Dr James Dixon</strong> is a conservation officer
                    and archaeologist who grew up in the Cheshire countryside
                    and is now based in Chester. He has worked as an urban
                    archaeologist around the UK, and received his PhD in
                    Creative Arts from the University of the West of England,
                    for a thesis which looked at the archaeology of contemporary
                    public art.
                  </p>
                  {/* <p> TODO: social link</p> */}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
