import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/chester3.jpg"
          altText="alt text"
          // credit="Image ©  Donna Leishman"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Chester Roam with Modernist Moocher</h1>
                  {/* <h2 className="typography_paragraph-8 typography_shadow">
                  With Wendy Connelly and Robin Baile
                  </h2> */}
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Roam around modern Chester with the &#39;Modernist Moocher&#39; Steve Marland, taking in
several cinemas, and a plethora of shopping centres.
                  </p>
                  <p>Pass under a variety of underpasses, and look up to an elevated lion, implausibly atop a
concrete car park.</p>
                  <p>Take a leisurely stroll to a very large leisure centre.</p>
                  <p>Plus lots of flats.</p>
                  <p>A walk on the periphery.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 7 Oct, 12pm</strong>
                    </p>
                    <p>
                      Storyhouse, Hunter St, Chester CH1 2AR
                    </p>
                    <p>
                      <strong>£8-10</strong> An exploration of the periphery. 
                    </p>
                    <p>(Members can apply discount at checkout.)</p>
                    <Button
                    href={
                      'https://the-modernist.org/collections/events/products/chester-roam-30-09-23'
                    }
                  >
                    Book Here
                  </Button>
                  <p><strong>NB:</strong> Walk will be approximately 2 hours in length - this is an outdoor event and is at the
mercy of the weather, please dress accordingly, and take extra care with road traffic
and all those other on street hazards!</p>
                  </Typography>
                  <IconLink align="top" content="2" href="/map" colour="green">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  {/* <p>
                    <strong>Donna Leishman‘s</strong> work is a combination of
                    critical writing, and digital and visual art with a
                    particular focus on the intersection of stories,
                    interactivity, and identity. Her work explores the reasons
                    why stories were created, and explores what new
                    possibilities they offer us culturally, by reframing
                    history, using folkloric traditions, and problematising
                    archetypes. Donna has had her work exhibited in museums,
                    galleries, conferences and festivals around the world.
                  </p> */}
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
