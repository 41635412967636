import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/03f5387dc0b8e2f94162b28a9ba23e72.jpg"
          altText="alt text"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>The Archaeology of Chester Contemporary</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Talk with James Dixon
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Chester Contemporary is not only taking place in Chester and
                    inspired by Chester, it’s an active part of the city’s past,
                    present and future. This talk by archaeologist of public
                    art, Dr James Dixon, will examine what Chester
                    Contemporary’s works mean to the city and its story, using
                    the perspective of a landscape archaeology created over
                    millions of years.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 11 Nov, 2pm</strong>
                    </p>
                    <p>
                      <strong>
                        Grosvenor Museum, 27 Grosvenor Street, Chester, CH1 2DD
                      </strong>
                    </p>
                    <p>
                      <strong>£2</strong>
                    </p>
                    <p>
                      <strong>Access</strong> The Lecture Theatre is on the
                      ground floor of the Museum and is accessible via lift from
                      street level. Other floors of the Museum have no lift
                      access.
                    </p>
                  </Typography>
                  <Button
                    href={'https://www.ticketsource.co.uk/chestercontemporary'}
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Dr James Dixon</strong> is a conservation officer
                    and archaeologist who grew up in the Cheshire countryside
                    and is now based in Chester. He has worked as an urban
                    archaeologist around the UK, and received his PhD in
                    Creative Arts from the University of the West of England for
                    a thesis which looked at the archaeology of contemporary
                    public art.
                  </p>
                  {/* <p> TODO: social link</p> */}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
