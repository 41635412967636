import './IconLink.css';

interface IconLinkProps {
  href?: string;
  children: React.ReactNode;
  content?: string;
  colour?: string;
  align?: string;
}

export const IconLink: React.FC<IconLinkProps> = ({
  href,
  content,
  colour,
  align,
  ...props
}) => {
  const classname = `element_icon-link ${
    colour && `element_icon-link__${colour}`
  } ${align && `element_icon-link__${align}`}`;

  return (
    <a href={href} className={classname} data-content={content} {...props} />
  );
};
