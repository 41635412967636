import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/9c3f5c1a0980e848e7b5481670ead452.jpg"
          altText="alt text"
          credit="Image: Machinations for a Desirous Bind, 2023. © Jacq Bebb."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Jacq Bebb</h1>
                  <h2 className="typography_heading-2 typography_shadow">
                    Works that explore skulking – the act of loitering with
                    intent
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    Skulking the In-Betweens (Queer Time), 2023
                  </p>
                  <p>
                    What do hometowns mean to us? We each have our own narrative
                    – this is Bebb’s. Three voiceover works thread down
                    Watergate Row charting a personal relationship with the
                    space: of formative years spent skulking around the
                    periphery, navigating the in-betweens and chasing the
                    unknown. Bebb invites us to eavesdrop on intimate thoughts
                    spoken out loud in this intensely personal new work.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      Can be heard as you walk down{' '}
                      <strong>Watergate Street Row, Chester, CH1 from 9am-9pm</strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Access</strong> Watergate Row South can be
                      accessed from pavement level via a ramp on Pier Point Lane
                      off Bridge St West.{' '}
                      <a
                        href="https://www.accessable.co.uk/cheshire-west-and-chester-council/cheshire-west-and-chester/access-guides/watergate-street-watergate-rows-north-and-south#f264493a-2d47-d444-9cb5-8e273eaf4e27"
                        target="_blank"
                      >
                        More access details here
                      </a>
                      . It can also be accessed from the back of the Rows at
                      Weaver Street.{' '}
                      <a
                        href="https://www.accessable.co.uk/cheshire-west-and-chester-council/cheshire-west-and-chester/access-guides/watergate-street-watergate-rows-north-and-south#2d73e436-69b9-964d-a6e2-86903ce2e485"
                        target="_blank"
                      >
                        More details here
                      </a>
                      .
                    </p>
                  </Typography>
                  <IconLink align="top" content="4" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_paragraph-8">
                    Skulking the In-Betweens (Desirous Bind), 2023
                  </p>
                  <p>
                    In this new piece, Bebb explores the knuckleduster, a
                    reoccurring motif that represents connection in all its
                    forms, from the very literal – of fist meeting face – to
                    more nuanced or formal interpretations of desire,
                    camaraderie and play. Bebb is interested in how we skulk and
                    cruise and make connections with others. This work is a
                    visualisation of that experience: a coming together of
                    knuckleduster forms, interlocking in a kind of melee,
                    designed to take on different postures at different times.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                      The Old Newgate Garden, next
to the Off The Wall pub, Pepper
St, Chester, CH1 1DA
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Access</strong> Can be viewed 24/7 from street
                      level
                    </p>
                  </Typography>
                  <IconLink align="top" content="4" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Jacq Bebb (b.1977, Chester, UK)</strong> lives and
                    works in Brooklyn, New York. Bebb has a visual and textual
                    practice that layers sculpture, drawing and audio works in a
                    rumination on desire through queer narratives and
                    experiences. Their current work delves into theories of
                    queer time, as counterpoint to chronological time. Drawing
                    upon research and personal experience, they question the
                    cultural implications of social norms and binary systems,
                    particularly in relation to queer connectedness and history.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      Skulking the In-Betweens is part of Chester Contemporary’s
                      Core Artist programme curated in conjunction with the
                      Emerging Artist programme by artistic director, Ryan
                      Gander.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
