import './footer.css';

import twitter from '../images/twitter.svg';
import insta from '../images/instagram.svg';
import facebook from '../images/facebook.svg';
import email from '../images/email.svg';

import ace from '../images/ace.svg';
import cwac from '../images/cwac.svg';
import edsential from '../images/edsential.svg';
import he from '../images/he.svg';
import hmf from '../images/hmf.svg';
import lhf from '../images/lhf.svg';
import oeg from '../images/oeg.svg';
import storyhouse from '../images/storyhouse.svg';
import lu from '../images/lu.svg';
import ukgov from '../images/ukgov.svg';
import uoc from '../images/uoc.svg';
import castlefield from '../images/castlefield.svg';
import recel from '../images/8037c0b76b2b2e324708be13f3339def.png';

export function Footer() {
  return (
    <footer>
      <div className="element_grid-container foundation_spacing_section">
        <div className="element_grid-row">
          <div className="element_grid-item">
            <div className="partner-logos">
              <div className="partner-logo_row">
                <img src={cwac} alt="Cheshire West and Chester Logo" />
                <img src={ukgov} alt="UK Gov Logo" />
                <img src={lu} alt="Levelling Up Logo" />
                <img src={ace} alt="Arts Council England Logo" />
                <img src={hmf} alt="Henry Moore Founation Logo" />
                <img src={he} alt="Historic England Logo" />
              </div>
              <div className="partner-logo_row">
                <img src={lhf} alt="Lottery Heritage Fund Logo" />
                <img src={storyhouse} alt="Storyhouse Logo" />
                <img src={uoc} alt="University of Chester Logo" />
                <img src={oeg} alt="Open Eye Gallery Logo" />
                <img src={edsential} alt="Edsential Logo" />
                <img src={castlefield} alt="Castlefield Gallery Logo" />
                <img
                  src={recel}
                  alt="Recclesia"
                  style={{ maxHeight: '45px' }}
                />
              </div>
            </div>
            <p className="flex-wrap">
              This project is part-funded by the UK Government through the UK
              Shared Prosperity Fund
            </p>
          </div>
        </div>
      </div>
      <div className="element_grid-container feature_black">
        <div className="element_grid-row">
          <div
            className="element_grid-item"
            data-grid-size="s-12 m-half l-third"
          >
            <div className="footer_link">
              <a
                href="mailto:hello@chestercontemporary.org?subject=I have a Chester Contemporary query or comment"
                className="inverted"
                target="_blank"
                rel="noreferrer"
              >
                Contact
              </a>
              <a
                href="https://cheshirewestandchester.us8.list-manage.com/subscribe?u=068c0d440921fc0d0ff8851f3&id=50f52232ca"
                className="inverted"
                target="_blank"
                rel="noreferrer"
              >
                Sign up for the latest news
              </a>
              <br />
              <a
                href="https://www.cheshirewestandchester.gov.uk/system-pages/privacy-notices/arts-team-projects-and-events-privacy-notice"
                className="inverted"
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </a>
            </div>
          </div>
          <div
            className="element_grid-item element_social-link-container"
            data-grid-size="s-12 m-half l-third"
            data-grid-start="l-9"
            data-grid-align="s-right"
          >
            <a
              href="https://twitter.com/ChesterContemp"
              target="_blank"
              rel="noreferrer"
              className="element_social-link"
            >
              <img src={twitter} alt="Chester Contemporary Twitter" />
            </a>
            <a
              href="https://www.instagram.com/chestercontemporary/"
              target="_blank"
              rel="noreferrer"
              className="element_social-link"
            >
              <img src={insta} alt="Chester Contemporary Instagram" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100080042449291"
              target="_blank"
              rel="noreferrer"
              className="element_social-link"
            >
              <img src={facebook} alt="Chester Contemporary Facebook" />
            </a>
            <a
              href="mailto:hello@chestercontemporary.org"
              target="_blank"
              rel="noreferrer"
              className="element_social-link"
            >
              <img src={email} alt="Chester Contemporary Email" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
