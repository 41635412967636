import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/6759fdc0fead4f79f072dd6e83640e2c.jpg"
          altText="alt text"
          credit="Image © Donna Leishman"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Hope is a group project</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Creative workshop with Donna Leishman
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    Join artist Donna Leishman to help make a site-specific
                    artwork at Hobby’s Well in Chester’s Grosvenor Park on
                    Thursday 19 October.
                  </p>
                  <p>
                    Hobby’s Well is an ancient natural spring that was once used
                    as a drinking and wishing well. Since 1867, the now dry well
                    has been kept at a distance behind an ornamental canopy and
                    railings, yet its legacy as a place for collective health
                    and hope still has the potential to be realised.
                  </p>
                  <p>
                    Together with Donna, you’ll use cloth and other materials to
                    create an artwork on the railings of the well, exploring the
                    site’s history, considering questions of ownership and
                    experiencing the power of collective making as a way to
                    reconnect with the past.
                  </p>
                  <p>
                    Hope is a group project{' '}
                    <em>
                      is part of Donna’s To Have & To Hold body of
                      socially-engaged critical practice, originally funded by
                      the Arts Council England, and Chester West and Chester
                      Council for Storyhouse. The aim of this work is to create
                      conditions for new participation with pieces of overlooked
                      folklore, both for the local community and wider audience.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Thurs 19 Oct, 2-5pm</strong>
                    </p>
                    <p>
                      <strong>Grosvenor Park Terrace, </strong>opposite{' '}
                      <strong>
                        Royal Chester Rowing Club, Chester, CH1 1SD
                      </strong>
                    </p>

                    <p>
                      <strong>Free.</strong> No need to book, just drop in.
                    </p>
                    <p>
                      Suitable for all ages, children under 16 to be accompanied
                      by an adult.
                    </p>
                    <p>
                      We’ll be outside so make sure you’re dressed for the
                      weather.
                    </p>
                    <p>All materials will be provided.</p>
                    <p>
                      <strong>Access</strong> from street level
                    </p>
                  </Typography>
                  <IconLink align="top" content="1" href="/map" colour="green">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Donna Leishman‘s</strong> work is a combination of
                    critical writing, and digital and visual art with a
                    particular focus on the intersection of stories,
                    interactivity, and identity. Her work explores the reasons
                    why stories were created, and explores what new
                    possibilities they offer us culturally, by reframing
                    history, using folkloric traditions, and problematising
                    archetypes. Donna has had her work exhibited in museums,
                    galleries, conferences and festivals around the world.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
