import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Typography } from '../components/Typography';
import { LineBreak } from '../components/LineBreak';
import { Hero } from '../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  const [pageData, setPageData] = useState({});
  const [currentPage, setCurrentPage] = useState('');
  const [featuredMedia, setFeaturedMedia] = useState('');

  let params: { [key: string]: any } = useParams();

  useEffect(() => {
    fetch('https://api.chestercontemporary.org/wp-json/wp/v2/pages/')
      .then((results) => results.json())
      .then((data) => {
        const formatted: { [key: string]: any } = {};
        data.forEach((item: any) => {
          formatted[item.slug] = item;
        });

        setPageData(formatted);

        //pageData[currentPage]._links['wp:featuredmedia'][0].href

        fetch(`${formatted[params['*']]._links['wp:featuredmedia'][0].href}`)
          .then((results) => results.json())
          .then((data) => {
            setFeaturedMedia(data.source_url);
            console.log(data.source_url, 'fayfiauhsfiu');
          });
      });

    setCurrentPage(params['*']);
  }, []);

  //

  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);

  // @ts-ignore
  if (!pageData[currentPage]) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc={featuredMedia}
          altText="alt text"
          //   credit="Image: Places I go when I can’t sleep (Explosive plans centring from the periphery), 2023. © Ryan Gander Studio."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                // data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>
                    {
                      // @ts-ignore
                      pageData[currentPage].title.rendered
                    }
                  </h1>
                </Typography>
              </div>
              <div
                className="element_grid-item"
                // data-grid-size="s-12 m-08"
                data-grid-start="m-2"
                dangerouslySetInnerHTML={{
                  // @ts-ignore
                  __html: pageData[currentPage].content.rendered,
                }}
              />
            </div>
            {/* <LineBreak /> */}
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
