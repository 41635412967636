import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/081fcbcbc0e16d398a1353cbdc06553a.jpg"
          altText="alt text"
          credit="Image: © James Lomax."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>James Lomax</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    Markets shift like sand V, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Markets shift like sand V continues James Lomax’s interest in the dissemination and privatisation of
markets in British towns and cities which often resulting in the pushing out of culture and identity as a
consequence. This work highlights how Chester has championed and held on to its heritage as a
place of historic importance in national and international trade.                    </p>
                    <p>
Lomax has installed market stall tarpaulins, a 17th century invention, used to cover cargo at sea
(these would have been used to carry goods up the River Dee into Chester Port), placed in the
windows and on rooftops of retail spaces across the city, largely visible by traversing the city walls.
Within Lomax&#39;s practice he frequently repurposes and reframes found objects by altering their
material composition or their intended use.
                  </p>
                  </Typography>
                  
                  <Typography style={{marginTop: '16px'}}>
                  <h4>Preface IV</h4>
                  <p>
For Preface IV, Lomax has appropriated existing municipal signage, inserting images he has taken of
markets from an archive of images that spans more than a decade. These Preface works, which often
accompany his exhibitions, appear to blend into the visual landscape of the city. He views them as a
subliminal appendage or preface to larger works or shows.</p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Fri 22 Sept – Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>Various locations TBC</strong>
                    </p>

                    <p>Can be viewed 24/7</p>
                  </Typography>
                  <IconLink align="top" content="11" href="/map">
                    Main tarpaulin site on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
<iframe src="https://snazzymaps.com/embed/527300" title="Tarpaulin location" width="100%"  style={{border:'none', aspectRatio: '1 / 1'}}></iframe>
              </div>
            </div>            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>James Lomax</strong> graduated from the Royal
                    Academy School in 2022 and works across multiple mediums.
                    <em>Within</em> his practice he often reuses and reframes
                    found objects by shifting their materiality or changing
                    their function/purpose. By presenting building site
                    hoardings as paintings or reproducing cardboard boxes from
                    luxury goods’ stores in cast concrete, this way of working
                    allows him to pose political questions or highlight specific
                    aspects of contemporary culture. With a strong art
                    historical influence to his practice, his work has a
                    specific interest in institutional critique and exhibition
                    making as well as site-reactive interventions.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      James’ work is part of Chester Contemporary’s Emerging
                      Artist programme curated in conjunction with the Core
                      Artist programme by artistic director, Ryan Gander; in
                      partnership with Storyhouse, Chester’s theatre, cinema and
                      library.
                    </em>
                  </p>
                  <p>
                    <em>
                      The programme offers the chance for five artists at a
                      pivotal point in their career to access mentoring and
                      professional development as well as an opportunity to
                      showcase their work within the Contemporary.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
