import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/2363129ba029693a23a0efe747ecfa13.jpg"
          altText="alt text"
          credit="Image: Rage Fluids, 2018. © Hannah Perry."
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>Hannah Perry</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                    No Tracksuits, No Trainers, 2023
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p className="typography_heading-2">
                    An abandoned space is the setting for a mesmerising
                    installation
                  </p>
                  <p>
                    No Tracksuits, No Trainers is
                    inspired by the industrial sounds
                    and materials of Perry’s youth in
                    Chester. A soundscape rumbles
                    across a wave of mirrored silver
                    metal sheets which vibrate and
                    distort in reply, bringing about
                    feelings of destabilization,
                    perhaps of our own response
                    to the way the work fills the
                    abandoned space around us.
                    Discarded car parts and objects
                    from Perry’s childhood memories
                    hang in space, encapsulated and
                    frozen in time, their feelings of
                    intimacy and stillness contrasting
                    with the raging industrial
                    atmosphere that threatens to
                    envelop them.
                  </p>
                  <p>
                    <em>
                      Supported with a grant from the Henry Moore Foundation.
                    </em>
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Sat 23 Sept - Fri 1 Dec, 2023</strong>
                    </p>
                    <p>
                      <strong>
                        The Old Chester Market Delivery Depot, Hamilton Place,
                        Chester, CH1 2BH
                      </strong>
                    </p>
                    <p>
                      <strong>FREE</strong> - no need to book
                    </p>
                    <p>
                      <strong>Open</strong> Weds-Sun, 11am-4pm
                    </p>
                    <p>
                      <strong>Access</strong> from pavement level on Hamilton
                      Place
                    </p>
                  </Typography>
                  <IconLink align="top" content="14" href="/map">
                    on the Chester Contemporary map
                  </IconLink>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Hannah Perry (b.1984, Chester, England)</strong>{' '}
                    lives and works in London. She received her BA in Fine Arts
                    from Goldsmiths College, University of London and her MA
                    from The Royal Academy of Art, London. Perry develops a
                    sprawling network of references, drawing inspiration from
                    personal experience, the testimony of others, and the
                    accelerated nature of our hyper-technological times. Her
                    works explore the complexities of our inner worlds, as well
                    as everyday physical and mental endurance and her practice
                    explores erotic discourse, motherhood, mental health, youth,
                    social class, and taste, alongside gender-coded industrial
                    materials.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                  <p>
                    <em>
                      No Tracksuits, No Trainers is part of Chester Contemporary’s Core Artist
                      programme curated in conjunction with the Emerging Artist
                      programme by artistic director, Ryan Gander.
                    </em>
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
