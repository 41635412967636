import React, { useEffect } from 'react';

import { Carousel } from '../../components/Carousel';
import { ContentCard } from '../../components/ContentCard';
import { Typography } from '../../components/Typography';
import { EventContainer } from '../../components/EventContainer';
import { LineBreak } from '../../components/LineBreak';
import { IconLink } from '../../components/IconLink';
import { Button } from '../../components/Button';
import { Hero } from '../../components/Hero';

// @ts-ignore
import { responsiveGrid, typography } from '@nr/quiddity';
console.log(responsiveGrid, typography);

export function Artist(props: { title: string }) {
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);
  return (
    <>
      <div className="feature_grey">
        {/* <Carousel /> */}
        <Hero
          imgSrc="/content-images/walks/make.jpeg"
          altText="Laura-Kate Draws build and make workshop"
        />
      </div>
      <main className="foundation_spacing_section">
        <div className="mini-feature_white">
          <div className="element_grid-container">
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <h1>The GROW Art Lab: Build and Make!</h1>
                  <h2 className="typography_paragraph-8 typography_shadow">
                  with Laura-Kate Draws
                  </h2>
                </Typography>
              </div>
            </div>
            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                  Celebrate fruit and vegetables inspired by the history of the Rows and its architecture in this
3D and sculptural workshop. Using a mix of pre-printed recycled cardboard and paper you’ll
make and decorate your own mini 3D garden sheds or paper leaf sculptures.
                  </p>
                  <p>
                  These sessions are drop in so you can stay for as long or as little as you like. The activity is ideal for
those aged 6+. Younger participants are welcome too, although they will need more support
throughout the session from the adult accompanying them.
                  </p>
                  <p>
                  You’ll need to pre-book a ticket for each child and accompanying adult, and children will
need to be accompanied by an adult throughout the session.
                  </p>
                </Typography>
              </div>
              <div className="element_grid-item" data-grid-size="s-12 m-03">
                <EventContainer>
                  <Typography>
                    <p>
                      <strong>Thurs 26 Oct</strong>
                    </p>
                    <p>
                      <strong>
                      Morning session</strong> 10am - 12 midday
                      <strong>Afternoon session</strong> 1-3pm
                      
                    </p>

                    <p>
                    Chester Visual Arts Gallery
(Former H&amp;M unit)
Grosvenor Shopping Centre
Eastgate St, Chester, CH1
                    </p>
                    <p>
                      <strong>Free</strong> – pre-booking recommended to guarantee your place during the busy half term week.
                    </p>
                    <p>All materials will be provided.</p>
                  </Typography>
                  <Button
                    href={
                      'https://www.ticketsource.co.uk/chestercontemporary'
                    }
                  >
                    Book Here
                  </Button>
                </EventContainer>
              </div>
            </div>
            <LineBreak />

            <div className="element_grid-row">
              <div
                className="element_grid-item"
                data-grid-size="s-12 m-08"
                data-grid-start="m-2"
              >
                <Typography>
                  <p>
                    <strong>Laura-Kate Draws</strong> has been working as a freelance illustrator and workshop practitioner since 2009.
She has worked as an illustratior for a children’s book and numerous sculpture painting projects for
Wild in Art. Other clients have included The Royal Shakespeare Company, The New Birmingham
Library, Arts Council Collection and The Royal Society of Chemistry. She has also developed a range
of illustrated products. Much of Laura-Kate’s work focuses on her passion for the environment and her
fascination with animals, flora, fauna and the conservation of nature. Her practice over the last 10
years has had a strong focus on working with community groups in a variety of settings, and has
worked closely with National Museums Liverpool delivering workshops and creating learning
resources. Empowering creativity, sharing skills and encouraging the use of imagination and
experimentation is at the core of Laura-Kate’s creative practice.
                  </p>
                  {/* <p> TODO: social link</p> */}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Artist;
