import logo from '../images/chester_contemporary.svg';
import wordmark from '../images/wordmark.svg';

import './nav.css';
import './header.css';

const Nav = () => (
  <>
    <input id="closeNav" className="hideInput" type="checkbox" />
    <nav>
      <label className="element_nav-close" htmlFor="closeNav">
        <span className="sr-only">close</span>
      </label>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/events">What's On</a>
        </li>
        <li>
          <a href="/map">Map</a>
        </li>
        <li>
          <a href="/visit">Visit</a>
        </li>
        <li>
          <a href="/GetInvolved">Get Involved</a>
        </li>
        <li>
          <a href="/About">About</a>
        </li>
        <li>
          <a href="/zine">CC 'Zine</a>
        </li>
        <li>
          <a href="/PastProjects">Past Projects</a>
        </li>
      </ul>
    </nav>
  </>
);

export function Header() {
  return (
    <header className="component_header">
      <div className="element_grid-container">
        <div className="element_grid-row">
          <div className="element_grid-item" data-grid-size="s-08 m-02 l-02">
            <a
              href="/"
              className="element_brand-logo-container anchor-no-highlight"
            >
              <img
                src={logo}
                alt="Chester Contemporary Logo"
                className="element_brand-logo"
              />
            </a>
          </div>
          <div
            className="element_grid-item element_wordmark-container"
            data-grid-size="s-04 m-10 l-10"
          >
            <div>
              <a href="/" className="anchor-no-highlight">
                <h1 className="sr-only s-hide">Chester Contemporary</h1>
              </a>
              <a className="anchor-no-highlight" href="/">
                <img
                  src={wordmark}
                  className="element_image-wordmark s-hide"
                  alt="Chester Contemporary"
                />
              </a>
              <Nav />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
